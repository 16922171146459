import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/styles";

import { GET_ALL_COUNTRIES_ISO } from "../../constants/endpoints";
import { GCP_BUCKET_URL_FLAGS } from "../../constants/data";
import { COUNTRIES } from "../../constants/countries";

import { getAllCountriesISO } from "../../Redux/Reducers/commonReducer";

import AutocompleteField from "./AutocompleteField";

const useStyles = makeStyles({
  flagIcon: {
    marginRight: 10,
    borderRadius: 2,
    objectFit: "cover",
    border: "1px solid #d3d3d3",
  },
});

const CountrySelect = ({ field, ...props }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { countriesISO } = useSelector((state) => state.common);

  const options = countriesISO.map(({ countryCode, countryName }) => ({
    id: countryCode,
    iconBefore: (
      <img
        className={classes.flagIcon}
        src={`${GCP_BUCKET_URL_FLAGS}${COUNTRIES[
          countryCode
        ]?.cca3?.toLowerCase()}.svg`}
        width={30}
        height={20}
        alt={countryName}
      />
    ),
    label: countryName,
  }));
  const value = field.value
    ? options.find((item) => item?.id === field.value)
    : field.value;
    
  const onChange = (value) => {
    field.onChange(value?.id || "");
  };

  useEffect(() => {
    dispatch(
      getAllCountriesISO({
        endpoint: GET_ALL_COUNTRIES_ISO,
      }),
    );
  }, []);

  return countriesISO.length ? (
    <AutocompleteField
      {...props}
      field={{ ...field, value, onChange }}
      size="small"
      data={options}
    />
  ) : null;
};

export default CountrySelect;
