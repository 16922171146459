import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { useForm, Controller } from "react-hook-form";
import { Button, Grid, Typography } from "@material-ui/core";
import { yupResolver } from "@hookform/resolvers/yup";

import { COLORS } from "../../constants/styles";
import { TITLE_BUTTONS } from "../../constants/titles";
import { REQUIRED_FIELDS } from "../../constants/data";
import { GET_STATES_BY_COUNTRY_ISO } from "../../constants/endpoints";

import { schema } from "../../utils/getSchemaVenueForm";
import { getList } from "../../dataProvider/API";

import TextFormField from "../TextFormField";
import CountrySelect from "../FormFields/CountrySelect";
import AutocompleteField from "../FormFields/AutocompleteField";

const useStyles = makeStyles({
  submit: {
    width: "80px",
    height: "30px",
    backgroundColor: COLORS.BACKGROUND_GREEN,
    marginRight: "10px",
    "& p": {
      color: COLORS.TEXT_WHITE,
      fontSize: "0.8rem",
    },
  },
  cansel: {
    width: "80px",
    height: "30px",
    "& p": {
      fontSize: "0.8rem",
    },
  },
  fieldForm: {
    width: "350px",
  },
});

const FieldOfCreate = ({
  dataForForms,
  handleClose,
  defaultValue,
  onSubmit,
}) => {
  const classes = useStyles();
  const { control, handleSubmit, watch, setValue } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValue,
  });

  const [states, setStates] = useState([]);

  const phone = watch("mobilePhone") || defaultValue.mobilePhone;
  const website = watch("website") || defaultValue.website;
  const country = watch("country") || defaultValue.country;
  const state = watch("state") || defaultValue.state;

  const statesOptions = states?.map(({ name, abbreviation }) => ({
    id: abbreviation,
    label: name,
  }));
  const stateValue = state
    ? statesOptions.find((item) => item?.id === state)
    : state;

  const onChangeCountry = () => {
    setValue("state", null);
    setStates([]);
  };

  const onChange = (name, value) => {
    switch (name) {
      case "country":
        return onChangeCountry();
      default:
        return;
    }
  };

  useEffect(() => {
    if (phone === "") setValue("mobilePhone", null);
    if (website === "") setValue("website", null);
  }, [phone, website, setValue]);

  useEffect(() => {
    if (country)
      getList(GET_STATES_BY_COUNTRY_ISO(country)).then((res) => setStates(res));
  }, [country]);

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          spacing={2}
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          {dataForForms
            .filter(([name]) =>
              name === "state" ? !!statesOptions?.length : true,
            )
            .map((elem) => {
              const [name, title] = elem;

              return (
                <Grid item key={name} className={classes.fieldForm}>
                  <Controller
                    render={({ field, fieldState }) => {
                      const props = {
                        field: {
                          ...field,
                          required: REQUIRED_FIELDS.includes(name),
                          onChange: (value) => {
                            field.onChange(value);
                            onChange(name, value);
                          },
                        },
                        label: title,
                        valueNormalizer: (value) => {
                          if (["email", "backupEmail"].includes(name))
                            return value?.toLowerCase()?.trim();

                          return value;
                        },
                      };

                      const onChangeState = (value) => {
                        field.onChange(value?.id || "");
                      };

                      switch (name) {
                        case "country":
                          return <CountrySelect {...props} />;
                        case "state":
                          return (
                            <AutocompleteField
                              {...props}
                              size="small"
                              field={{
                                ...props.field,
                                value: stateValue,
                                onChange: onChangeState,
                              }}
                              data={statesOptions}
                            />
                          );
                        default:
                          return (
                            <TextFormField {...props} fieldState={fieldState} />
                          );
                      }
                    }}
                    control={control}
                    name={name}
                    defaultValue={defaultValue[name] || null}
                  />
                </Grid>
              );
            })}

          <Grid item>
            <Button
              className={classes.submit}
              type="submit"
              variant="contained"
            >
              <Typography>{TITLE_BUTTONS.FINISH}</Typography>
            </Button>
            <Button
              className={classes.cansel}
              variant="outlined"
              onClick={handleClose}
            >
              <Typography>{TITLE_BUTTONS.CANCEL}</Typography>
            </Button>
          </Grid>
        </Grid>
      </form>
    </React.Fragment>
  );
};

FieldOfCreate.propTypes = {
  dataForForms: PropTypes.array,
  handleClose: PropTypes.func,
  onSubmit: PropTypes.func,
  defaultValue: PropTypes.object,
};

export default FieldOfCreate;
