import { checkAppVersion } from "./utils";

export const createHeadersFormOptions = (options) => {
  const headers = options.headers || {
    Accept: "application/json",
    Authorization: localStorage.getItem("jwtToken")
      ? `Bearer ${localStorage.getItem("jwtToken")}`
      : "",
  };
  const requestHeaders = new Headers(headers);

  if (
    !requestHeaders.has("Content-Type") &&
    !(options && (!options.method || options.method === "GET")) &&
    !(options && options.body && options.body instanceof FormData)
  ) {
    requestHeaders.set("Content-Type", "application/json");
  }

  return requestHeaders;
};

export const fetchJson = async (url, options = {}) => {
  const requestHeaders = createHeadersFormOptions(options);

  return fetch(url, { ...options, headers: requestHeaders })
    .then((response) =>
      response.text().then((text) => ({
        status: response.status,
        statusText: response.statusText,
        headers: response.headers,
        body: text,
      })),
    )
    .then(({ status, headers, body }) => {
      checkAppVersion(headers.get("x-version"));

      return Promise.resolve({ status, headers, body, json: JSON.parse(body) });
    });
};
