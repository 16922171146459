import { dataProvider } from "./dataProvider";

export const IS_ENV_DEVELOPMENT = process.env.NODE_ENV === "development";
export const API_URL = "/restapi";

const Provider = dataProvider(API_URL);

export const getList = (endpoint, params) => Provider.getList(endpoint, params);

export const deleteOne = (endpoint) => Provider.delete(endpoint);

export const create = (endpoint, data) => Provider.create(endpoint, data);

export const update = (endpoint, data) => Provider.update(endpoint, data);
