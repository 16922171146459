import { dtoFormFields } from "../constants/data";

export const getFillDtoVenues = (params, idAddress) => {
  const cloneDtoFormFields = { ...dtoFormFields };
  const addressDto = { ...dtoFormFields.addressDto };

  cloneDtoFormFields.aquaticCenter = params.aquaticCenter;
  cloneDtoFormFields.building = params.building;
  cloneDtoFormFields.poolName = params.poolName;
  cloneDtoFormFields.schoolInstitution = params.schoolInstitution;

  for (let key in addressDto) {
    addressDto[key] = params[key];
  }

  if (idAddress) addressDto.id = idAddress;

  return { ...cloneDtoFormFields, addressDto };
};

export const getFillDtoTeam = (
  params,
  dtoFormTeamFields,
  contactInformationID,
) => {
  const cloneDtoTeam = {
    ...dtoFormTeamFields,
    contactInformation: { ...dtoFormTeamFields.contactInformation },
    governingBodies: [...dtoFormTeamFields.governingBodies],
    venues: [...dtoFormTeamFields.venues],
    logoChanged: !!params.logoChanged,
  };
  cloneDtoTeam.contactInformation = { ...dtoFormTeamFields.contactInformation };
  cloneDtoTeam.governingBodies = [...dtoFormTeamFields.governingBodies];
  cloneDtoTeam.venues = [...dtoFormTeamFields.venues];

  for (let key in cloneDtoTeam) {
    if (key === "contactInformation") {
      for (let n in dtoFormTeamFields[key]) {
        cloneDtoTeam[key][n] = params[n];
      }
    } else if (key === "cloudAlias") {
      cloneDtoTeam.logoUrl = params[key] || null;
    } else {
      cloneDtoTeam[key] = params[key] || null;
    }
  }
  if (contactInformationID) {
    cloneDtoTeam.contactInformation.id = contactInformationID;
  }

  return cloneDtoTeam;
};
