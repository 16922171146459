import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { getList, deleteOne, create, update } from "../../dataProvider/API";

import { ALERT_TEXT } from "../../constants/titles";

const initialState = {
  loading: false,
  fetchDataTeams: {},
  governingBodies: {},
};

export const getTeams = createAsyncThunk(
  "teams/getTeams",
  ({ endpoint, params }) => getList(endpoint, params),
);

export const getGoverningBodies = createAsyncThunk(
  "teams/getGoverningBodies",
  ({ endpoint }) => getList(endpoint),
);

export const deleteTeam = createAsyncThunk("teams/deleteTeam", (endpoint) =>
  deleteOne(endpoint),
);

export const createTeam = createAsyncThunk(
  "teams/create",
  ({ endpoint, data }) => create(endpoint, data),
);

export const updateTeam = createAsyncThunk(
  "teams/update",
  ({ endpoint, data }) => update(endpoint, data),
);

export const teamsSlice = createSlice({
  name: "teams",
  initialState,
  extraReducers: {
    [getTeams.pending]: (state) => {
      state.loading = true;
    },
    [getTeams.fulfilled]: (state, action) => {
      state.fetchDataTeams = action.payload;
      state.loading = false;
    },
    [getTeams.rejected]: (state, action, x) => {
      state.loading = false;
      toast.error(ALERT_TEXT.BAD_REQUEST);
    },
    [deleteTeam.pending]: (state) => {
      state.loading = true;
    },
    [deleteTeam.fulfilled]: (state) => {
      state.loading = false;
      toast.success(ALERT_TEXT.SUCCESS_DELETE_TEAM);
    },
    [deleteTeam.rejected]: (state) => {
      state.loading = false;
      toast.error(ALERT_TEXT.BAD_REQUEST);
    },
    [getGoverningBodies.fulfilled]: (state, action) => {
      state.governingBodies = action.payload;
    },
    [updateTeam.fulfilled]: () => {
      toast.success(ALERT_TEXT.SUCCESS_UPDATE_TEAM);
    },
    [updateTeam.rejected]: () => {
      toast.error(ALERT_TEXT.BAD_REQUEST);
    },
    [createTeam.fulfilled]: (state, action) => {
      toast.success(ALERT_TEXT.SUCCESS_CREATE_TEAM);
    },
    [createTeam.rejected]: () => {
      toast.error(ALERT_TEXT.BAD_REQUEST);
    },
  },
});

export default teamsSlice.reducer;
