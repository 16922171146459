import React from "react";
import PropTypes from "prop-types";
import { TextField } from "@material-ui/core";

const InputMail = ({ field, fieldState, label }) => {
  const { name, value, ...other } = field;
  const { error, invalid } = fieldState;
  const fieldValue = value === null ? "" : value?.toLowerCase()?.trim();

  return (
    <TextField
      id={name}
      label={label}
      variant="outlined"
      size="small"
      margin="normal"
      fullWidth
      value={fieldValue}
      error={invalid}
      helperText={error?.message}
      {...other}
    />
  );
};

InputMail.propTypes = {
  field: PropTypes.object,
  fieldState: PropTypes.object,
  label: PropTypes.string,
};

export default InputMail;
