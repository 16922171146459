import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useForm, Controller } from "react-hook-form";
import { makeStyles } from "@material-ui/styles";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { yupResolver } from "@hookform/resolvers/yup";

import { TITLE_BUTTONS } from "../../constants/titles";
import { COLORS } from "../../constants/styles";
import {
  formTeamFields,
  GCP_BUCKET_URL_AVATARS,
  REQUIRED_FIELDS,
} from "../../constants/data";
import { GET_STATES_BY_COUNTRY_ISO } from "../../constants/endpoints";

import { schema } from "../../utils/getSchemaTeamsForm";
import { getList } from "../../dataProvider/API";

import TextForm from "../../components/FormFields/TextForm";
import SelectCheckMarks from "../../components/FormFields/SelectCheckMarks";
import FileForm from "../../components/FormFields/FileForm";
import AutocompleteField from "../../components/FormFields/AutocompleteField";
import CountrySelect from "../../components/FormFields/CountrySelect";
import SelectCheckboxFilter from "../../components/FormFields/SelectCheckboxFilter";

const useStyles = makeStyles(() => ({
  submit: {
    width: "80px",
    height: "30px",
    backgroundColor: COLORS.BACKGROUND_GREEN,
    marginRight: "10px",
    "& p": {
      color: COLORS.TEXT_WHITE,
      fontSize: "0.8rem",
    },
  },
  cansel: {
    width: "80px",
    height: "30px",
    "& p": {
      fontSize: "0.8rem",
    },
  },
  fieldForm: {
    width: "350px",
  },
  logoWrapper: {
    width: "100%",
    height: "200px",
    marginBottom: "10px",
  },
  logoImage: {
    maxWidth: "100%",
    maxHeight: "100%",
    margin: "0 auto",
    display: "block",
  },
}));

const TeamCreateForm = ({
  handleClose,
  onSubmit,
  defaultValue,
  isViewField,
  governingBodiesTitle,
  venuesTitle,
  conference: conferenceList,
}) => {
  const classes = useStyles();
  const { control, handleSubmit, watch, setValue } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValue,
  });

  const [image, setImage] = useState(
    `${GCP_BUCKET_URL_AVATARS}${defaultValue.cloudAlias}_master`,
  );
  const [isDisabled, setIsDisabled] = useState(false);
  const [states, setStates] = useState([]);

  const governingBodies =
    watch("governingBodies") || defaultValue.governingBodies;
  const country = watch("country") || defaultValue.country;
  const state = watch("state") || defaultValue.state;
  const conference = watch("conference") || defaultValue.conference;

  const statesOptions = states?.map(({ name, abbreviation }) => ({
    id: abbreviation,
    label: name,
  }));
  const stateValue = state
    ? statesOptions.find((item) => item?.id === state)
    : state;
  const conferenceOptions = conferenceList?.map(({ id, name }) => ({
    id,
    label: name,
  }));
  const conferenceValue = conference
    ? conferenceOptions.find((item) => item?.id === conference)
    : conference;

  const sortVenuesTitle = (sheckedVenues, allVenues) => {
    let result = null;

    if (sheckedVenues) {
      const uncheckedVenues = [...allVenues].filter(
        (e) => !sheckedVenues.find((el) => el.id === e.id),
      );
      result = [...sheckedVenues, ...uncheckedVenues];
    } else {
      result = allVenues;
    }

    return result;
  };

  const onChangeCountry = () => {
    setValue("state", null);
    setStates([]);
  };

  const onChange = (name, value) => {
    switch (name) {
      case "country":
        return onChangeCountry();
      default:
        return;
    }
  };

  const onErrorImage = () => {
    setImage();
    setValue("cloudAlias", "");
  };

  useEffect(() => {
    const isFindGoverning = governingBodies?.find(
      (e) => e.abbreviation === "NCAA",
    );

    setIsDisabled(!isFindGoverning);
  }, [governingBodies]);

  useEffect(() => {
    if (country)
      getList(GET_STATES_BY_COUNTRY_ISO(country)).then((res) => setStates(res));
  }, [country]);

  return (
    <React.Fragment>
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            spacing={2}
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item md={12} className={classes.fieldForm}>
              <Controller
                render={({ field, fieldState }) => (
                  <TextForm
                    field={{
                      ...field,
                      required: formTeamFields.name.short === "name",
                    }}
                    label={formTeamFields.name.title}
                    size="small"
                    fieldState={fieldState}
                  />
                )}
                control={control}
                name={formTeamFields.name.short}
                defaultValue={defaultValue[formTeamFields.name.short] || ""}
              />
            </Grid>

            <Grid item md={12} className={classes.fieldForm}>
              <Controller
                render={({ field }) => (
                  <SelectCheckMarks
                    field={field}
                    label={formTeamFields.governingBodies.title}
                    size={"small"}
                    options={governingBodiesTitle}
                  />
                )}
                control={control}
                name={formTeamFields.governingBodies.short}
                defaultValue={
                  defaultValue[formTeamFields.governingBodies.short] || []
                }
              />
            </Grid>

            {isViewField && (
              <Grid item md={12} className={classes.fieldForm}>
                <Controller
                  render={({ field, fieldState }) => (
                    <TextForm
                      field={field}
                      label={formTeamFields.coachEmail.title}
                      size={"small"}
                      fieldState={fieldState}
                      valueNormalizer={(value) => value?.toLowerCase()?.trim()}
                    />
                  )}
                  control={control}
                  name={formTeamFields.coachEmail.short}
                  defaultValue={
                    defaultValue[formTeamFields.coachEmail.short] || ""
                  }
                />
              </Grid>
            )}

            <Grid item md={12} className={classes.fieldForm}>
              <Typography variant="h6">
                {formTeamFields.teamInfo.title}
              </Typography>
            </Grid>

            {formTeamFields.teamInfo.data.map(({ id, title, short }) => (
              <Grid item md={12} className={classes.fieldForm} key={id}>
                <Controller
                  render={({ field, fieldState }) => {
                    const props = {
                      field: {
                        ...field,
                        onChange: (value) => {
                          field.onChange(value);
                          onChange(short, value);
                        },
                      },
                      label: title,
                      size: "small",
                    };

                    const onChangeConference = (value) => {
                      field.onChange(value?.id || "");
                    };

                    switch (short) {
                      case "conference":
                        return (
                          <AutocompleteField
                            label={title}
                            size={"small"}
                            data={conferenceOptions}
                            isDisabled={isDisabled}
                            field={{
                              ...props.field,
                              value: conferenceValue,
                              onChange: onChangeConference,
                            }}
                          />
                        );
                      default:
                        return <TextForm {...props} fieldState={fieldState} />;
                    }
                  }}
                  control={control}
                  name={short}
                  defaultValue={defaultValue[short] || null}
                />
              </Grid>
            ))}

            <Grid item md={12} className={classes.fieldForm}>
              <Typography variant="h6">
                {formTeamFields.contactInformation.title}
              </Typography>
            </Grid>

            {formTeamFields.contactInformation.data
              .filter(({ short }) =>
                short === "state" ? !!statesOptions?.length : true,
              )
              .map(({ id, short, title }) => (
                <Grid item md={12} className={classes.fieldForm} key={id}>
                  <Controller
                    render={({ field, fieldState }) => {
                      const props = {
                        field: {
                          ...field,
                          required: REQUIRED_FIELDS.includes(short),
                          onChange: (value) => {
                            field.onChange(value);
                            onChange(short, value);
                          },
                        },
                        label: title,
                        size: "small",
                        valueNormalizer: (value) => {
                          if (["email", "backupEmail"].includes(short))
                            return value?.toLowerCase()?.trim();

                          return value;
                        },
                      };

                      const onChangeState = (value) => {
                        field.onChange(value?.id || "");
                      };

                      switch (short) {
                        case "country":
                          return <CountrySelect {...props} />;
                        case "state":
                          return (
                            <AutocompleteField
                              {...props}
                              size="small"
                              field={{
                                ...props.field,
                                value: stateValue,
                                onChange: onChangeState,
                              }}
                              data={statesOptions}
                            />
                          );
                        default:
                          return (
                            <TextForm {...props} fieldState={fieldState} />
                          );
                      }
                    }}
                    control={control}
                    name={short}
                    defaultValue={defaultValue[short] || null}
                  />
                </Grid>
              ))}

            <Grid item md={12} className={classes.fieldForm}>
              <Typography variant="h6">
                {formTeamFields.venues.title}
              </Typography>
            </Grid>

            <Grid item md={12} className={classes.fieldForm}>
              <Controller
                render={({ field }) => (
                  <SelectCheckboxFilter
                    field={field}
                    label={formTeamFields.venues.title}
                    size={"small"}
                    options={sortVenuesTitle(defaultValue.venues, venuesTitle)}
                  />
                )}
                control={control}
                name={formTeamFields.venues.short}
                defaultValue={defaultValue[formTeamFields.venues.short] || []}
              />
            </Grid>

            <Grid item md={12} className={classes.fieldForm}>
              <Typography variant="h6">
                {formTeamFields.cloudAlias.title}
              </Typography>
            </Grid>

            <Grid item md={12} className={classes.fieldForm}>
              {image && (
                <Box className={classes.logoWrapper}>
                  <img
                    src={image}
                    onError={onErrorImage}
                    className={classes.logoImage}
                    alt="logo"
                  />
                </Box>
              )}

              <Controller
                render={({ field, ...rest }) => (
                  <FileForm
                    setImage={setImage}
                    field={field}
                    setValue={setValue}
                  />
                )}
                control={control}
                name={formTeamFields.cloudAlias.short}
                defaultValue={
                  defaultValue[formTeamFields.cloudAlias.short] || ""
                }
              />
            </Grid>

            <Grid item>
              <Button
                className={classes.submit}
                type="submit"
                variant="contained"
              >
                <Typography>{TITLE_BUTTONS.FINISH}</Typography>
              </Button>
              <Button
                className={classes.cansel}
                variant="outlined"
                onClick={handleClose}
              >
                <Typography>{TITLE_BUTTONS.CANCEL}</Typography>
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </React.Fragment>
  );
};

TeamCreateForm.propTypes = {
  handleClose: PropTypes.func,
  onSubmit: PropTypes.func,
  options: PropTypes.array,
  defaultValue: PropTypes.object,
  isViewField: PropTypes.bool,
  governingBodiesTitle: PropTypes.array,
  venuesTitle: PropTypes.array,
  data: PropTypes.array,
  conference: PropTypes.array,
};

export default TeamCreateForm;
