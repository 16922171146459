import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { TablePagination } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent: "center",
    marginTop: "10px",
    marginBottom: "10px",
    width: "100%",
    borderBottom: "none",
  },
});

const ListPagination = ({ filters, fetchFunction }) => {
  const classes = useStyles();
  const count = Math.ceil(filters.total);
  
  const [rowsPerPage, setRowsPerPage] = useState(filters.limit || 10);

  const handleChangePage = (event, newPage) => {
    fetchFunction({ limit: rowsPerPage, page: newPage });
  };

  const handleChangeRowsPerPage = (event) => {
    const limit = event.target.value;
    const rowsPerPage = parseInt(limit, 10);
    const amountPages = Math.ceil(count / rowsPerPage);
    const currentPage = filters.page;
    const page = currentPage + 1 > amountPages ? amountPages - 1 : currentPage;

    fetchFunction({ limit, page });
    setRowsPerPage(rowsPerPage);
  };

  return (
    <TablePagination
      component="div"
      className={classes.root}
      count={count}
      page={filters.page || 0}
      onPageChange={handleChangePage}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  );
};

ListPagination.propTypes = {
  filters: PropTypes.shape({
    limit: PropTypes.number,
    page: PropTypes.number,
    total: PropTypes.number,
  }),
  fetchFunction: PropTypes.func,
};

export default ListPagination;
