import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import { ALERT_TEXT } from "../../constants/titles";
import { API_URL } from "../../dataProvider/API";

import { fetchJson } from "../../dataProvider/fetch";

const initialState = {
  user: {},
  loading: false,
};

export const userLogIn = createAsyncThunk("user/login", async (params) => {
  const response = await fetchJson(`${API_URL}/auth/login/admin`, {
    method: "POST",
    headers: {
      Accept: "application/json;pagingspec=1.0, application/xml;pagingspec=1.0",
    },
    body: JSON.stringify(params),
  });
  const { userId, admin: isAdmin, jwtToken: token } = response.json;

  return {
    userId,
    token,
    isAdmin,
  };
});

export const userSlice = createSlice({
  name: "user",
  initialState,
  extraReducers: {
    [userLogIn.pending]: (state) => {
      state.loading = true;
    },
    [userLogIn.fulfilled]: (state, { payload }) => {
      payload?.token && localStorage.setItem("jwtToken", payload.token);
      state.user = payload;
      state.loading = false;
    },
    [userLogIn.rejected]: () => {
      toast.error(ALERT_TEXT.LOGIN);
    },
  },
});

export default userSlice.reducer;
