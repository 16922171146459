import * as Yup from "yup";
import { formTeamFields } from "../constants/data";

export const schema = Yup.object().shape({
  [formTeamFields.name.short]: Yup.string()
    .required("Enter team name, please")
    .transform((curr, orig) => (orig.trim() === "" ? null : curr))
    .min(1, "Must be min 1 symbols")
    .max(255, "Must be max 255 symbols")
    .nullable(),
  [formTeamFields.coachEmail.short]: Yup.string()
    .email("Email is not valid.")
    .nullable(),
  streetOne: Yup.string().max(255, "Must be max 255 symbols").nullable(),
  streetTwo: Yup.string().max(255, "Must be max 255 symbols").nullable(),
  streetThree: Yup.string().max(255, "Must be max 255 symbols").nullable(),
  abbreviation: Yup.string().max(5, "Must be max 5 symbols").nullable(),
});
