import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { isEmpty } from "lodash";

import {
  getVenues,
  deleteVenue,
  createVenue,
  updateVenue,
} from "./sliceReducer";
import {
  DELETE_VENUE,
  GET_ALL_VENUES,
  CREATE_VENUE,
  EDIT_VENUE,
  GET_SEARCH_VENUES,
} from "../../constants/endpoints";
import {
  VENUE_FIELDS_TITLE,
  defaultParamsViewTable,
  formValueFields,
} from "../../constants/data";
import { TITLE_BUTTONS, PAGES_TITLES, TITLES } from "../../constants/titles";
import { getFillDtoVenues } from "../../utils/getFillDto";
import { updateDataVenues } from "../../utils/getDataForTable";
import { addSearchValue } from "../../Redux/Reducers/commonReducer";

import Header from "../../containers/Header";
import DataTable from "../../containers/DataTable";
import ConfirmDialog from "../../components/ConfirmDialog";
import FieldOfCreate from "../../components/FieldOfCreate";
import ButtonsGroup from "../../components/ButtonsGroup";

const useStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent: "center",
  },
  progress: {
    marginTop: "50px",
  },
});

const Venues = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { fetchData, loading } = useSelector((state) => state.venues);
  const { search } = useSelector((state) => state.common);

  const [pagination, setPagination] = useState({});
  const [dtos, setDtos] = useState([]);
  const [defaultValueForm, setDefaultValueForm] = useState(null);
  const [selectedDto, setSetselectedDto] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [selectID, setSelectID] = useState(null);

  const dataForForms = Object.entries(VENUE_FIELDS_TITLE).filter(
    (e) => e[0] !== "id",
  );

  const getFetchData = (params) => {
    const endpoint = search ? GET_SEARCH_VENUES(search) : GET_ALL_VENUES;
    dispatch(
      getVenues({
        endpoint,
        params,
      }),
    );
  };

  const [open, setOpen] = useState(false);

  const handleClickOpen = (data) => {
    setOpen(true);
    if (data.id) {
      setDefaultValueForm({
        ...formValueFields,
        ...data,
      });
      const selected = dtos.find((e) => e.id === data.id);
      setSetselectedDto(selected);
    } else {
      setDefaultValueForm(formValueFields);
    }
  };

  const handleClose = () => {
    setDefaultValueForm(null);
    setOpen(false);
    setSetselectedDto({});
  };

  const handleOpenModal = (id) => {
    setSelectID(id);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setSelectID(null);
    setOpenModal(false);
  };

  const deleteOneVenue = () => {
    dispatch(deleteVenue(DELETE_VENUE(selectID))).then(() => {
      const {
        page = defaultParamsViewTable.page,
        limit = defaultParamsViewTable.limit,
      } = pagination;

      getFetchData({
        ...defaultParamsViewTable,
        page,
        limit,
      });
    });
  };

  const createOneVenue = (params) => {
    dispatch(
      createVenue({
        endpoint: CREATE_VENUE,
        data: getFillDtoVenues(params),
      }),
    ).then(() => {
      const {
        page = defaultParamsViewTable.page,
        limit = defaultParamsViewTable.limit,
      } = pagination;

      getFetchData({
        ...defaultParamsViewTable,
        page,
        limit,
      });
    });
  };

  const updateOneVenue = (params, id, idAddress) => {
    dispatch(
      updateVenue({
        endpoint: EDIT_VENUE,
        data: { ...getFillDtoVenues(params, idAddress), id },
      }),
    ).then(() => {
      const {
        page = defaultParamsViewTable.page,
        limit = defaultParamsViewTable.limit,
      } = pagination;

      getFetchData({
        ...defaultParamsViewTable,
        page,
        limit,
      });
    });
  };

  const onSubmit = (params) => {
    if (!isEmpty(selectedDto)) {
      const { id } = selectedDto;
      const idAddress = selectedDto["addressDto"].id;

      updateOneVenue(params, id, idAddress);
    } else {
      createOneVenue(params);
    }

    handleClose();
  };

  const onChangePage = (payload) => {
    setPagination({
      ...pagination,
      ...payload,
    });
    const params = {
      ...defaultParamsViewTable,
      ...payload,
    };
    const endpoint = search ? GET_SEARCH_VENUES(search) : GET_ALL_VENUES;
    dispatch(
      getVenues({
        endpoint,
        params,
      }),
    );
  };

  useEffect(() => {
    dispatch(addSearchValue(""));
  }, []);

  useEffect(() => {
    if (!isEmpty(fetchData)) {
      const { currentPage, totalElements, dtos } = fetchData;

      setPagination((state) => ({
        ...state,
        page: currentPage,
        total: totalElements,
      }));
      setDtos(dtos);
    }
  }, [fetchData]);

  useEffect(() => {
    dispatch(
      getVenues({
        endpoint: search ? GET_SEARCH_VENUES(search) : GET_ALL_VENUES,
        params: defaultParamsViewTable,
      }),
    );
  }, [search]);

  return (
    <React.Fragment>
      <Header
        handleClickOpen={handleClickOpen}
        title={PAGES_TITLES.VENUES}
        titleButton={TITLE_BUTTONS.CREATE_VENUE}
      />
      <div className={classes.root}>
        {loading ? (
          <CircularProgress className={classes.progress} />
        ) : (
          <DataTable
            updateItem={handleClickOpen}
            fetchData={dtos}
            pagination={pagination}
            removeRow={handleOpenModal}
            onChangePage={onChangePage}
            titlesHead={Object.values(VENUE_FIELDS_TITLE)}
            updateData={updateDataVenues}
            fieldsRow={VENUE_FIELDS_TITLE}
          />
        )}
      </div>
      {open && (
        <ConfirmDialog
          open
          handleClose={handleClose}
          title={TITLES.CREATE_VENUE}
        >
          {!!defaultValueForm ? (
            <FieldOfCreate
              dataForForms={dataForForms}
              defaultValue={defaultValueForm}
              handleClose={handleClose}
              onSubmit={onSubmit}
            />
          ) : null}
        </ConfirmDialog>
      )}
      {openModal && (
        <ConfirmDialog
          open
          handleClose={handleCloseModal}
          title={TITLES.DELETE_MODAL}
        >
          <ButtonsGroup
            handleClose={handleCloseModal}
            handleFunction={deleteOneVenue}
            submitTitle={TITLE_BUTTONS.DELETE}
            closeTitle={TITLE_BUTTONS.CANCEL}
          />
        </ConfirmDialog>
      )}
    </React.Fragment>
  );
};

export default Venues;
