const CLIENT_APP_VERSION = "clientAppVersion";

export const checkAppVersion = (appVersionFormResponse) => {
  const currentAppVersion = localStorage.getItem(CLIENT_APP_VERSION);

  if (!appVersionFormResponse) return;

  if (currentAppVersion === null) {
    localStorage.setItem(CLIENT_APP_VERSION, appVersionFormResponse);
  } else if (
    currentAppVersion &&
    currentAppVersion !== appVersionFormResponse
  ) {
    localStorage.setItem(CLIENT_APP_VERSION, appVersionFormResponse);
    alert("New updates have been deployed. Click OK to apply.");
    window.location.reload();
  }
};
