import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { getList } from "../../dataProvider/API";

const initialState = {
  loading: false,
  countriesISO: [],
  conferenceID: [],
  search: "",
};

export const getAllCountriesISO = createAsyncThunk(
  "common/getAllCountries",
  ({ endpoint }) => getList(endpoint),
);

export const getAllConferenceID = createAsyncThunk(
  "common/getAllConferenceID",
  ({ endpoint }) => getList(endpoint),
);

export const commonReducer = createSlice({
  name: "common",
  initialState,
  reducers: {
    addSearchValue(state, action) {
      state.search = action.payload;
    },
  },
  extraReducers: {
    [getAllCountriesISO.fulfilled]: (state, { payload }) => {
      state.countriesISO = payload.sort(
        ({ countryName: a }, { countryName: b }) => a.localeCompare(b),
      );
    },
    [getAllConferenceID.fulfilled]: (state, action) => {
      state.conferenceID = action.payload;
    },
  },
});

export const { addSearchValue } = commonReducer.actions;
export default commonReducer.reducer;
