import React from "react";
import PropTypes from "prop-types";
import { Input, InputLabel, Box, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { toast } from "react-toastify";

import { ALERT_TEXT, TITLE_BUTTONS } from "../../constants/titles";
import { IMAGE_FORMATS } from "../../constants/types";

const useStyles = makeStyles({
  root: {
    height: "40px",
  },
  wrapInput: {
    display: "none",
  },
  label: {
    position: "relative",
    top: "0px",
    textAlign: "center",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: "4px",
    height: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    color: "rgba(0, 0, 0, 0.87)",
    textTransform: "uppercase",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
});

const FileForm = ({ setImage, field, setValue }) => {
  const classes = useStyles();

  const { name, onChange, value } = field;
  const accept = Object.values(IMAGE_FORMATS)
    .map((e) => `.${e}`)
    .join(", ");

  const chooseFile = ({ target }) => {
    const fileReader = new FileReader();
    const type = target.files[0].type;
    const isAcceptTypes = Object.values(IMAGE_FORMATS)
      .map((e) => `image/${e}`)
      .some((acceptType) => acceptType === type);

    if (isAcceptTypes) {
      fileReader.readAsDataURL(target.files[0]);
      fileReader.onload = (e) => {
        setImage(e.target.result);
        onChange(e.target.result.split(",")[1]);
        setValue("logoChanged", true);
      };

      return;
    }

    toast.error(ALERT_TEXT.BAD_FILE_FORMAT);
  };

  const handleDelete = () => {
    setImage("");
    onChange("");
    setValue("logoChanged", true);
  };

  return (
    <Box className={classes.root}>
      {value ? (
        <Button onClick={handleDelete} fullWidth variant="outlined">
          {TITLE_BUTTONS.DELETE}
        </Button>
      ) : (
        <>
          <InputLabel htmlFor={name} className={classes.label}>
            {TITLE_BUTTONS.CHOOSE_FILE}
          </InputLabel>
          <Input
            id={name}
            className={classes.wrapInput}
            type="file"
            fullWidth
            onChange={chooseFile}
            inputProps={{ accept }}
          />
        </>
      )}
    </Box>
  );
};

FileForm.propTypes = {
  setImage: PropTypes.func.isRequired,
  field: PropTypes.object,
};

export default FileForm;
