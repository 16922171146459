import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { isEmpty } from "lodash";

import { PAGES_TITLES, TITLE_BUTTONS, TITLES } from "../../constants/titles";
import {
  TEAM_FIELDS_TITLE,
  dtoFormTeamFields,
  defaultParamsTeamsTable,
} from "../../constants/data";
import {
  deleteTeam,
  getTeams,
  createTeam,
  updateTeam,
  getGoverningBodies,
} from "../../pages/Teams/sliceReducer";
import {
  CREATE_TEAM,
  DELETE_TEAM,
  EDIT_TEAM,
  GET_ALL_COUNTRIES_ISO,
  GET_ALL_GOVERNING_BODIES,
  GET_ALL_TEAMS,
  GET_ALL_VENUES_SHORT,
  GET_CONFERENCES,
  GET_SEARCH_TEAMS,
} from "../../constants/endpoints";
import { updateDataTeams } from "../../utils/getDataForTable";
import { getFillDtoTeam } from "../../utils/getFillDto";
import { getVenuesShort } from "../Venues/sliceReducer";
import {
  getAllCountriesISO,
  getAllConferenceID,
  addSearchValue,
} from "../../Redux/Reducers/commonReducer";

import Header from "../../containers/Header";
import DataTable from "../../containers/DataTable";
import TeamCreateForm from "../../containers/TeamCreateForm";
import ConfirmDialog from "../../components/ConfirmDialog";
import ButtonsGroup from "../../components/ButtonsGroup";

const useStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent: "center",
  },
  progress: {
    marginTop: "50px",
  },
});

const Teams = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { fetchDataTeams, governingBodies, loading } = useSelector(
    (state) => state.teams,
  );
  const { fetchDataShortVenues } = useSelector((state) => state.venues);
  const { conferenceID, search } = useSelector((state) => state.common);

  const [open, setOpen] = useState(false);
  const [dtos, setDtos] = useState([]);
  const [pagination, setPagination] = useState({});
  const [defaultValueForm, setDefaultValueForm] = useState(null);
  const [selectedDto, setSetselectedDto] = useState({});
  const [isViewCoachEmail, setIsViewCoachEmail] = useState(true);
  const [governingBodiesTitle, setGoverningBodiesTitle] = useState([]);
  const [venuesTitle, setVenuesTitle] = useState([]);
  const [conference, setConference] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectID, setSelectID] = useState(null);

  const isEditMode = !isEmpty(selectedDto);

  const handleClickOpen = (data) => {
    setOpen(true);

    if (data.id) {
      setSetselectedDto(data);
      setDefaultValueForm(data);
      setIsViewCoachEmail(false);
    } else {
      setSetselectedDto({});
      setDefaultValueForm({});
      setIsViewCoachEmail(true);
    }
  };

  const handleClose = () => {
    setDefaultValueForm(null);
    setOpen(false);
    setSetselectedDto({});
  };

  const handleOpenModal = (id) => {
    setSelectID(id);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setSelectID(null);
    setOpenModal(false);
  };

  const deleteOneTeam = () => {
    dispatch(deleteTeam(DELETE_TEAM(selectID))).then(() => {
      dispatch(
        getTeams({
          endpoint: search ? GET_SEARCH_TEAMS(search) : GET_ALL_TEAMS,
          params: {
            ...defaultParamsTeamsTable,
            ...pagination,
          },
        }),
      );
    });
  };

  const onChangePage = (payload) => {
    setPagination({
      ...pagination,
      ...payload,
    });

    dispatch(
      getTeams({
        endpoint: search ? GET_SEARCH_TEAMS(search) : GET_ALL_TEAMS,
        params: {
          ...defaultParamsTeamsTable,
          ...payload,
        },
      }),
    );
  };

  const updateOneTeam = (params) => {
    const oldTeamData = dtos.find(({ id }) => id === selectedDto.id);
    const data = getFillDtoTeam(
      {
        ...params,
        id: selectedDto?.id,
        conference: conference.find(({ id }) => id === params?.conference),
      },
      oldTeamData,
      selectedDto?.contactInformationID,
    );

    dispatch(
      updateTeam({
        endpoint: EDIT_TEAM,
        data,
      }),
    ).then(() => {
      dispatch(
        getTeams({
          endpoint: search ? GET_SEARCH_TEAMS(search) : GET_ALL_TEAMS,
          params: {
            ...defaultParamsTeamsTable,
            ...pagination,
          },
        }),
      );
    });
  };

  const createOneTeam = (params) => {
    const data = getFillDtoTeam(
      {
        ...params,
        conference: conference.find(({ id }) => id === params?.conference),
      },
      dtoFormTeamFields,
    );

    dispatch(
      createTeam({
        endpoint: CREATE_TEAM,
        data,
      }),
    ).then(() => {
      dispatch(
        getTeams({
          endpoint: search ? GET_SEARCH_TEAMS(search) : GET_ALL_TEAMS,
          params: {
            ...defaultParamsTeamsTable,
            ...pagination,
          },
        }),
      );
    });
  };

  const onSubmit = (params) => {
    if (isEditMode) {
      const { cloudAlias, logoUrl } = selectedDto;

      updateOneTeam({
        ...params,
        cloudAlias:
          params.cloudAlias === cloudAlias ? logoUrl : params.cloudAlias,
      });
    } else {
      createOneTeam(params);
    }

    handleClose();
  };

  useEffect(() => {
    dispatch(addSearchValue(""));

    dispatch(
      getGoverningBodies({
        endpoint: GET_ALL_GOVERNING_BODIES,
      }),
    );

    dispatch(
      getVenuesShort({
        endpoint: GET_ALL_VENUES_SHORT("Id"),
      }),
    );

    dispatch(
      getAllCountriesISO({
        endpoint: GET_ALL_COUNTRIES_ISO,
      }),
    );

    dispatch(
      getAllConferenceID({
        endpoint: GET_CONFERENCES,
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(governingBodies)) {
      setGoverningBodiesTitle(governingBodies);
    }
  }, [governingBodies]);

  useEffect(() => {
    if (!isEmpty(conferenceID)) {
      setConference(conferenceID);
    }
  }, [conferenceID]);

  useEffect(() => {
    if (!isEmpty(fetchDataShortVenues)) {
      setVenuesTitle(fetchDataShortVenues);
    }
  }, [fetchDataShortVenues]);

  useEffect(() => {
    if (!isEmpty(fetchDataTeams)) {
      const { currentPage, totalElements, teamsDto } = fetchDataTeams;

      setPagination((state) => ({
        ...state,
        page: currentPage,
        total: totalElements,
      }));
      setDtos(teamsDto);
    }
  }, [fetchDataTeams]);

  useEffect(() => {
    dispatch(
      getTeams({
        endpoint: search ? GET_SEARCH_TEAMS(search) : GET_ALL_TEAMS,
        params: defaultParamsTeamsTable,
      }),
    );
  }, [dispatch, search]);

  return (
    <React.Fragment>
      <Header
        handleClickOpen={handleClickOpen}
        title={PAGES_TITLES.TEAMS}
        titleButton={TITLE_BUTTONS.CREATE_TEAM}
      />
      <div className={classes.root}>
        {loading ? (
          <CircularProgress className={classes.progress} />
        ) : (
          <DataTable
            updateItem={handleClickOpen}
            fetchData={dtos}
            pagination={pagination}
            removeRow={handleOpenModal}
            onChangePage={onChangePage}
            titlesHead={Object.values(TEAM_FIELDS_TITLE)}
            updateData={updateDataTeams}
            fieldsRow={TEAM_FIELDS_TITLE}
          />
        )}
      </div>
      {open && (
        <ConfirmDialog
          open
          handleClose={handleClose}
          title={isEditMode ? TITLES.EDIT_TEAM : TITLES.CREATE_TEAM}
        >
          {!!defaultValueForm ? (
            <TeamCreateForm
              defaultValue={defaultValueForm}
              handleClose={handleClose}
              onSubmit={onSubmit}
              isViewField={isViewCoachEmail}
              governingBodiesTitle={governingBodiesTitle}
              venuesTitle={venuesTitle}
              conference={conference}
            />
          ) : null}
        </ConfirmDialog>
      )}
      <ConfirmDialog
        open={openModal}
        handleClose={handleCloseModal}
        title={TITLES.DELETE_MODAL}
      >
        <ButtonsGroup
          handleClose={handleCloseModal}
          handleFunction={deleteOneTeam}
          submitTitle={TITLE_BUTTONS.DELETE}
          closeTitle={TITLE_BUTTONS.CANCEL}
        />
      </ConfirmDialog>
    </React.Fragment>
  );
};

export default Teams;
