import React, { useEffect, useState } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";

import { PATHS } from "./constants/paths";

import TopBar from "./components/TopBar";
import Venues from "./pages/Venues";
import Teams from "./pages/Teams";
import Login from "./pages/Login";
import UserManagment from "./pages/UserManagment";

function App() {
  const { user } = useSelector((state) => state.user);
  const [token, setToken] = useState(localStorage.getItem("jwtToken"));

  const ProtectedRoute = ({ token, children }) => {
    if (!token) {
      return <Navigate to={PATHS.LOGIN} replace />;
    }
    return children ? children : <Outlet />;
  };

  const logOut = () => {
    localStorage.removeItem("jwtToken");
    setToken(localStorage.getItem("jwtToken"));
  };

  useEffect(() => {
    setToken(localStorage.getItem("jwtToken"));
  }, [user]);

  return (
    <BrowserRouter>
      {token && <TopBar logOut={logOut} />}
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <Routes>
        <Route
          index
          element={
            <ProtectedRoute token={token}>
              <Navigate to={PATHS.TEAMS} replace />;
            </ProtectedRoute>
          }
        />
        <Route element={<ProtectedRoute token={token} />}>
          <Route path={PATHS.VENUES} element={<Venues />} />
          <Route path={PATHS.TEAMS} element={<Teams />} />
          <Route path={PATHS.USER_MANAGEMENT} element={<UserManagment />} />
        </Route>
        <Route
          path={PATHS.LOGIN}
          element={token ? <Navigate to={PATHS.TEAMS} replace /> : <Login />}
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
