export const COLORS = {
  BACKGROUND_LIGHT_GREY: "#f8f8f8",
  BACKGROUND_GREY: "#e2e2e2",
  BACKGROUND_WHITE: "#ffff",
  TEXT_GREY: "#828081",
  TEXT_WHITE: "#ffff",
  TEXT_GREEN: "#209d9e",
  BACKGROUND_GREEN: "#209d9e",
  SUBMIT: "#0497f1",
};
