import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";

import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";

const useStyles = makeStyles({
  title: {
    textAlign: "center",
  },
});

const ConfirmDialog = ({ open, handleClose, title, children }) => {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={handleClose} scroll="body">
      <DialogTitle className={classes.title}>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};

ConfirmDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  title: PropTypes.string,
  children: PropTypes.element,
};

export default ConfirmDialog;
