import React from "react";
import { Container, Typography } from "@material-ui/core";

const UserManagment = () => {
  return (
    <Container>
      <Typography>Pege "Use Managment" empty</Typography>
    </Container>
  );
};

export default UserManagment;
