import React from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useForm, Controller } from "react-hook-form";

import { TITLES, TITLE_BUTTONS } from "../../constants/titles";
import { COLORS } from "../../constants/styles";
import { formLoginFields } from "../../constants/data";

import InputPassword from "./InputPassword";
import InputMail from "./InputMail";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "320px",
  },
  title: {
    textAlign: "center",
    marginBottom: "10px",
    fontWeight: "bold",
  },
  button: {
    backgroundColor: COLORS.SUBMIT,
    width: "100%",
    color: COLORS.TEXT_WHITE,
    marginTop: "20px",
  },
});

const schema = Yup.object().shape({
  [formLoginFields.username]: Yup.string().required("User name is required"),
  [formLoginFields.password]: Yup.string().required("Please provide password"),
});

const LoginForm = ({ onSubmit }) => {
  const classes = useStyles();
  const { control, handleSubmit } = useForm({ resolver: yupResolver(schema) });

  return (
    <Box className={classes.root}>
      <Typography component="p" variant="h5" className={classes.title}>
        {TITLES.ADMIN_PANEL}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          render={({ field, fieldState }) => (
            <InputMail field={field} fieldState={fieldState} label="E-Mail" />
          )}
          control={control}
          defaultValue=""
          name={formLoginFields.username}
        />
        <Controller
          render={({ field, fieldState }) => (
            <InputPassword
              field={field}
              fieldState={fieldState}
              label="Password"
            />
          )}
          control={control}
          defaultValue=""
          name={formLoginFields.password}
        />
        <Button variant="contained" className={classes.button} type="submit">
          {TITLE_BUTTONS.LOGIN}
        </Button>
      </form>
    </Box>
  );
};

LoginForm.propTypes = {
  onSubmit: PropTypes.func,
};

export default LoginForm;
