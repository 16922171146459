import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";

import { TextField } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    width: "100%",
    "& input": {
      width: "300px",
    },
  },
});

const TextFormField = ({
  field,
  label,
  fieldState,
  valueNormalizer = (value) => value,
}) => {
  const classes = useStyles();

  const { name, value, ...other } = field;
  const { error, invalid } = fieldState;

  const fieldValue = value === null ? "" : valueNormalizer(value);

  return (
    <TextField
      id={name}
      label={label}
      variant="outlined"
      className={classes.root}
      size="small"
      value={fieldValue}
      error={invalid}
      helperText={error?.message}
      {...other}
    />
  );
};

TextFormField.propTypes = {
  field: PropTypes.any,
  label: PropTypes.string,
  fieldState: PropTypes.object,
};

export default TextFormField;
