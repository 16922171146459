export const COUNTRIES = {
  PF: {
    cca2: "PF",
    cca3: "PYF",
    ccn3: "258",
  },
  MF: {
    cca2: "MF",
    cca3: "MAF",
    ccn3: "663",
  },
  VE: {
    cca2: "VE",
    cca3: "VEN",
    ccn3: "862",
  },
  RE: {
    cca2: "RE",
    cca3: "REU",
    ccn3: "638",
  },
  SV: {
    cca2: "SV",
    cca3: "SLV",
    ccn3: "222",
  },
  DM: {
    cca2: "DM",
    cca3: "DMA",
    ccn3: "212",
  },
  GI: {
    cca2: "GI",
    cca3: "GIB",
    ccn3: "292",
  },
  KE: {
    cca2: "KE",
    cca3: "KEN",
    ccn3: "404",
  },
  BR: {
    cca2: "BR",
    cca3: "BRA",
    ccn3: "076",
  },
  MV: {
    cca2: "MV",
    cca3: "MDV",
    ccn3: "462",
  },
  US: {
    cca2: "US",
    cca3: "USA",
    ccn3: "840",
  },
  CK: {
    cca2: "CK",
    cca3: "COK",
    ccn3: "184",
  },
  NU: {
    cca2: "NU",
    cca3: "NIU",
    ccn3: "570",
  },
  SC: {
    cca2: "SC",
    cca3: "SYC",
    ccn3: "690",
  },
  CF: {
    cca2: "CF",
    cca3: "CAF",
    ccn3: "140",
  },
  TK: {
    cca2: "TK",
    cca3: "TKL",
    ccn3: "772",
  },
  VU: {
    cca2: "VU",
    cca3: "VUT",
    ccn3: "548",
  },
  GM: {
    cca2: "GM",
    cca3: "GMB",
    ccn3: "270",
  },
  GY: {
    cca2: "GY",
    cca3: "GUY",
    ccn3: "328",
  },
  FK: {
    cca2: "FK",
    cca3: "FLK",
    ccn3: "238",
  },
  BE: {
    cca2: "BE",
    cca3: "BEL",
    ccn3: "056",
  },
  EH: {
    cca2: "EH",
    cca3: "ESH",
    ccn3: "732",
  },
  TR: {
    cca2: "TR",
    cca3: "TUR",
    ccn3: "792",
  },
  VC: {
    cca2: "VC",
    cca3: "VCT",
    ccn3: "670",
  },
  PK: {
    cca2: "PK",
    cca3: "PAK",
    ccn3: "586",
  },
  AX: {
    cca2: "AX",
    cca3: "ALA",
    ccn3: "248",
  },
  IR: {
    cca2: "IR",
    cca3: "IRN",
    ccn3: "364",
  },
  ID: {
    cca2: "ID",
    cca3: "IDN",
    ccn3: "360",
  },
  NZ: {
    cca2: "NZ",
    cca3: "NZL",
    ccn3: "554",
  },
  AF: {
    cca2: "AF",
    cca3: "AFG",
    ccn3: "004",
  },
  GU: {
    cca2: "GU",
    cca3: "GUM",
    ccn3: "316",
  },
  AL: {
    cca2: "AL",
    cca3: "ALB",
    ccn3: "008",
  },
  CD: {
    cca2: "CD",
    cca3: "COD",
    ccn3: "180",
  },
  CI: {
    cca2: "CI",
    cca3: "CIV",
    ccn3: "384",
  },
  SD: {
    cca2: "SD",
    cca3: "SDN",
    ccn3: "729",
  },
  TL: {
    cca2: "TL",
    cca3: "TLS",
    ccn3: "626",
  },
  LU: {
    cca2: "LU",
    cca3: "LUX",
    ccn3: "442",
  },
  SA: {
    cca2: "SA",
    cca3: "SAU",
    ccn3: "682",
  },
  KH: {
    cca2: "KH",
    cca3: "KHM",
    ccn3: "116",
  },
  NP: {
    cca2: "NP",
    cca3: "NPL",
    ccn3: "524",
  },
  GF: {
    cca2: "GF",
    cca3: "GUF",
    ccn3: "254",
  },
  MY: {
    cca2: "MY",
    cca3: "MYS",
    ccn3: "458",
  },
  RW: {
    cca2: "RW",
    cca3: "RWA",
    ccn3: "646",
  },
  TH: {
    cca2: "TH",
    cca3: "THA",
    ccn3: "764",
  },
  AQ: {
    cca2: "AQ",
    cca3: "ATA",
    ccn3: "010",
  },
  JO: {
    cca2: "JO",
    cca3: "JOR",
    ccn3: "400",
  },
  CH: {
    cca2: "CH",
    cca3: "CHE",
    ccn3: "756",
  },
  KM: {
    cca2: "KM",
    cca3: "COM",
    ccn3: "174",
  },
  XK: {
    cca2: "XK",
    cca3: "UNK",
  },
  IM: {
    cca2: "IM",
    cca3: "IMN",
    ccn3: "833",
  },
  ME: {
    cca2: "ME",
    cca3: "MNE",
    ccn3: "499",
  },
  HK: {
    cca2: "HK",
    cca3: "HKG",
    ccn3: "344",
  },
  JE: {
    cca2: "JE",
    cca3: "JEY",
    ccn3: "832",
  },
  TJ: {
    cca2: "TJ",
    cca3: "TJK",
    ccn3: "762",
  },
  BG: {
    cca2: "BG",
    cca3: "BGR",
    ccn3: "100",
  },
  EG: {
    cca2: "EG",
    cca3: "EGY",
    ccn3: "818",
  },
  MW: {
    cca2: "MW",
    cca3: "MWI",
    ccn3: "454",
  },
  CV: {
    cca2: "CV",
    cca3: "CPV",
    ccn3: "132",
  },
  BJ: {
    cca2: "BJ",
    cca3: "BEN",
    ccn3: "204",
  },
  MA: {
    cca2: "MA",
    cca3: "MAR",
    ccn3: "504",
  },
  IE: {
    cca2: "IE",
    cca3: "IRL",
    ccn3: "372",
  },
  MD: {
    cca2: "MD",
    cca3: "MDA",
    ccn3: "498",
  },
  DK: {
    cca2: "DK",
    cca3: "DNK",
    ccn3: "208",
  },
  TM: {
    cca2: "TM",
    cca3: "TKM",
    ccn3: "795",
  },
  FM: {
    cca2: "FM",
    cca3: "FSM",
    ccn3: "583",
  },
  MC: {
    cca2: "MC",
    cca3: "MCO",
    ccn3: "492",
  },
  BB: {
    cca2: "BB",
    cca3: "BRB",
    ccn3: "052",
  },
  DZ: {
    cca2: "DZ",
    cca3: "DZA",
    ccn3: "012",
  },
  TF: {
    cca2: "TF",
    cca3: "ATF",
    ccn3: "260",
  },
  ER: {
    cca2: "ER",
    cca3: "ERI",
    ccn3: "232",
  },
  LS: {
    cca2: "LS",
    cca3: "LSO",
    ccn3: "426",
  },
  TZ: {
    cca2: "TZ",
    cca3: "TZA",
    ccn3: "834",
  },
  ML: {
    cca2: "ML",
    cca3: "MLI",
    ccn3: "466",
  },
  NE: {
    cca2: "NE",
    cca3: "NER",
    ccn3: "562",
  },
  AD: {
    cca2: "AD",
    cca3: "AND",
    ccn3: "020",
  },
  GB: {
    cca2: "GB",
    cca3: "GBR",
    ccn3: "826",
  },
  DE: {
    cca2: "DE",
    cca3: "DEU",
    ccn3: "276",
  },
  VI: {
    cca2: "VI",
    cca3: "VIR",
    ccn3: "850",
  },
  SO: {
    cca2: "SO",
    cca3: "SOM",
    ccn3: "706",
  },
  SX: {
    cca2: "SX",
    cca3: "SXM",
    ccn3: "534",
  },
  CM: {
    cca2: "CM",
    cca3: "CMR",
    ccn3: "120",
  },
  DO: {
    cca2: "DO",
    cca3: "DOM",
    ccn3: "214",
  },
  GN: {
    cca2: "GN",
    cca3: "GIN",
    ccn3: "324",
  },
  NA: {
    cca2: "NA",
    cca3: "NAM",
    ccn3: "516",
  },
  MS: {
    cca2: "MS",
    cca3: "MSR",
    ccn3: "500",
  },
  GS: {
    cca2: "GS",
    cca3: "SGS",
    ccn3: "239",
  },
  SN: {
    cca2: "SN",
    cca3: "SEN",
    ccn3: "686",
  },
  BV: {
    cca2: "BV",
    cca3: "BVT",
    ccn3: "074",
  },
  SB: {
    cca2: "SB",
    cca3: "SLB",
    ccn3: "090",
  },
  FR: {
    cca2: "FR",
    cca3: "FRA",
    ccn3: "250",
  },
  SH: {
    cca2: "SH",
    cca3: "SHN",
    ccn3: "654",
  },
  MO: {
    cca2: "MO",
    cca3: "MAC",
    ccn3: "446",
  },
  AR: {
    cca2: "AR",
    cca3: "ARG",
    ccn3: "032",
  },
  BA: {
    cca2: "BA",
    cca3: "BIH",
    ccn3: "070",
  },
  AI: {
    cca2: "AI",
    cca3: "AIA",
    ccn3: "660",
  },
  GG: {
    cca2: "GG",
    cca3: "GGY",
    ccn3: "831",
  },
  DJ: {
    cca2: "DJ",
    cca3: "DJI",
    ccn3: "262",
  },
  KN: {
    cca2: "KN",
    cca3: "KNA",
    ccn3: "659",
  },
  SY: {
    cca2: "SY",
    cca3: "SYR",
    ccn3: "760",
  },
  PR: {
    cca2: "PR",
    cca3: "PRI",
    ccn3: "630",
  },
  PE: {
    cca2: "PE",
    cca3: "PER",
    ccn3: "604",
  },
  SM: {
    cca2: "SM",
    cca3: "SMR",
    ccn3: "674",
  },
  AU: {
    cca2: "AU",
    cca3: "AUS",
    ccn3: "036",
  },
  NC: {
    cca2: "NC",
    cca3: "NCL",
    ccn3: "540",
  },
  JM: {
    cca2: "JM",
    cca3: "JAM",
    ccn3: "388",
  },
  KZ: {
    cca2: "KZ",
    cca3: "KAZ",
    ccn3: "398",
  },
  SL: {
    cca2: "SL",
    cca3: "SLE",
    ccn3: "694",
  },
  PW: {
    cca2: "PW",
    cca3: "PLW",
    ccn3: "585",
  },
  KR: {
    cca2: "KR",
    cca3: "KOR",
    ccn3: "410",
  },
  PM: {
    cca2: "PM",
    cca3: "SPM",
    ccn3: "666",
  },
  BZ: {
    cca2: "BZ",
    cca3: "BLZ",
    ccn3: "084",
  },
  PG: {
    cca2: "PG",
    cca3: "PNG",
    ccn3: "598",
  },
  IS: {
    cca2: "IS",
    cca3: "ISL",
    ccn3: "352",
  },
  AS: {
    cca2: "AS",
    cca3: "ASM",
    ccn3: "016",
  },
  BF: {
    cca2: "BF",
    cca3: "BFA",
    ccn3: "854",
  },
  PT: {
    cca2: "PT",
    cca3: "PRT",
    ccn3: "620",
  },
  TW: {
    cca2: "TW",
    cca3: "TWN",
    ccn3: "158",
  },
  JP: {
    cca2: "JP",
    cca3: "JPN",
    ccn3: "392",
  },
  CN: {
    cca2: "CN",
    cca3: "CHN",
    ccn3: "156",
  },
  LB: {
    cca2: "LB",
    cca3: "LBN",
    ccn3: "422",
  },
  LK: {
    cca2: "LK",
    cca3: "LKA",
    ccn3: "144",
  },
  GT: {
    cca2: "GT",
    cca3: "GTM",
    ccn3: "320",
  },
  RS: {
    cca2: "RS",
    cca3: "SRB",
    ccn3: "688",
  },
  MG: {
    cca2: "MG",
    cca3: "MDG",
    ccn3: "450",
  },
  SZ: {
    cca2: "SZ",
    cca3: "SWZ",
    ccn3: "748",
  },
  RO: {
    cca2: "RO",
    cca3: "ROU",
    ccn3: "642",
  },
  AG: {
    cca2: "AG",
    cca3: "ATG",
    ccn3: "028",
  },
  CW: {
    cca2: "CW",
    cca3: "CUW",
    ccn3: "531",
  },
  ZM: {
    cca2: "ZM",
    cca3: "ZMB",
    ccn3: "894",
  },
  ZW: {
    cca2: "ZW",
    cca3: "ZWE",
    ccn3: "716",
  },
  TN: {
    cca2: "TN",
    cca3: "TUN",
    ccn3: "788",
  },
  AE: {
    cca2: "AE",
    cca3: "ARE",
    ccn3: "784",
  },
  MN: {
    cca2: "MN",
    cca3: "MNG",
    ccn3: "496",
  },
  NO: {
    cca2: "NO",
    cca3: "NOR",
    ccn3: "578",
  },
  GL: {
    cca2: "GL",
    cca3: "GRL",
    ccn3: "304",
  },
  UY: {
    cca2: "UY",
    cca3: "URY",
    ccn3: "858",
  },
  BS: {
    cca2: "BS",
    cca3: "BHS",
    ccn3: "044",
  },
  RU: {
    cca2: "RU",
    cca3: "RUS",
    ccn3: "643",
  },
  VG: {
    cca2: "VG",
    cca3: "VGB",
    ccn3: "092",
  },
  WF: {
    cca2: "WF",
    cca3: "WLF",
    ccn3: "876",
  },
  TD: {
    cca2: "TD",
    cca3: "TCD",
    ccn3: "148",
  },
  LC: {
    cca2: "LC",
    cca3: "LCA",
    ccn3: "662",
  },
  YE: {
    cca2: "YE",
    cca3: "YEM",
    ccn3: "887",
  },
  UM: {
    cca2: "UM",
    cca3: "UMI",
    ccn3: "581",
  },
  SE: {
    cca2: "SE",
    cca3: "SWE",
    ccn3: "752",
  },
  SJ: {
    cca2: "SJ",
    cca3: "SJM",
    ccn3: "744",
  },
  LA: {
    cca2: "LA",
    cca3: "LAO",
    ccn3: "418",
  },
  LV: {
    cca2: "LV",
    cca3: "LVA",
    ccn3: "428",
  },
  CO: {
    cca2: "CO",
    cca3: "COL",
    ccn3: "170",
  },
  GD: {
    cca2: "GD",
    cca3: "GRD",
    ccn3: "308",
  },
  BL: {
    cca2: "BL",
    cca3: "BLM",
    ccn3: "652",
  },
  CA: {
    cca2: "CA",
    cca3: "CAN",
    ccn3: "124",
  },
  HM: {
    cca2: "HM",
    cca3: "HMD",
    ccn3: "334",
  },
  IN: {
    cca2: "IN",
    cca3: "IND",
    ccn3: "356",
  },
  GW: {
    cca2: "GW",
    cca3: "GNB",
    ccn3: "624",
  },
  MK: {
    cca2: "MK",
    cca3: "MKD",
    ccn3: "807",
  },
  PY: {
    cca2: "PY",
    cca3: "PRY",
    ccn3: "600",
  },
  HR: {
    cca2: "HR",
    cca3: "HRV",
    ccn3: "191",
  },
  CR: {
    cca2: "CR",
    cca3: "CRI",
    ccn3: "188",
  },
  UG: {
    cca2: "UG",
    cca3: "UGA",
    ccn3: "800",
  },
  BQ: {
    cca2: "BQ",
    cca3: "BES",
    ccn3: "535",
  },
  BO: {
    cca2: "BO",
    cca3: "BOL",
    ccn3: "068",
  },
  TG: {
    cca2: "TG",
    cca3: "TGO",
    ccn3: "768",
  },
  YT: {
    cca2: "YT",
    cca3: "MYT",
    ccn3: "175",
  },
  MH: {
    cca2: "MH",
    cca3: "MHL",
    ccn3: "584",
  },
  KP: {
    cca2: "KP",
    cca3: "PRK",
    ccn3: "408",
  },
  NL: {
    cca2: "NL",
    cca3: "NLD",
    ccn3: "528",
  },
  IO: {
    cca2: "IO",
    cca3: "IOT",
    ccn3: "086",
  },
  MT: {
    cca2: "MT",
    cca3: "MLT",
    ccn3: "470",
  },
  MU: {
    cca2: "MU",
    cca3: "MUS",
    ccn3: "480",
  },
  NF: {
    cca2: "NF",
    cca3: "NFK",
    ccn3: "574",
  },
  HN: {
    cca2: "HN",
    cca3: "HND",
    ccn3: "340",
  },
  ES: {
    cca2: "ES",
    cca3: "ESP",
    ccn3: "724",
  },
  EE: {
    cca2: "EE",
    cca3: "EST",
    ccn3: "233",
  },
  KG: {
    cca2: "KG",
    cca3: "KGZ",
    ccn3: "417",
  },
  CL: {
    cca2: "CL",
    cca3: "CHL",
    ccn3: "152",
  },
  BM: {
    cca2: "BM",
    cca3: "BMU",
    ccn3: "060",
  },
  GQ: {
    cca2: "GQ",
    cca3: "GNQ",
    ccn3: "226",
  },
  LR: {
    cca2: "LR",
    cca3: "LBR",
    ccn3: "430",
  },
  PN: {
    cca2: "PN",
    cca3: "PCN",
    ccn3: "612",
  },
  LY: {
    cca2: "LY",
    cca3: "LBY",
    ccn3: "434",
  },
  LI: {
    cca2: "LI",
    cca3: "LIE",
    ccn3: "438",
  },
  VA: {
    cca2: "VA",
    cca3: "VAT",
    ccn3: "336",
  },
  CX: {
    cca2: "CX",
    cca3: "CXR",
    ccn3: "162",
  },
  OM: {
    cca2: "OM",
    cca3: "OMN",
    ccn3: "512",
  },
  PH: {
    cca2: "PH",
    cca3: "PHL",
    ccn3: "608",
  },
  PL: {
    cca2: "PL",
    cca3: "POL",
    ccn3: "616",
  },
  FO: {
    cca2: "FO",
    cca3: "FRO",
    ccn3: "234",
  },
  BH: {
    cca2: "BH",
    cca3: "BHR",
    ccn3: "048",
  },
  BY: {
    cca2: "BY",
    cca3: "BLR",
    ccn3: "112",
  },
  SI: {
    cca2: "SI",
    cca3: "SVN",
    ccn3: "705",
  },
  GP: {
    cca2: "GP",
    cca3: "GLP",
    ccn3: "312",
  },
  QA: {
    cca2: "QA",
    cca3: "QAT",
    ccn3: "634",
  },
  VN: {
    cca2: "VN",
    cca3: "VNM",
    ccn3: "704",
  },
  MR: {
    cca2: "MR",
    cca3: "MRT",
    ccn3: "478",
  },
  SG: {
    cca2: "SG",
    cca3: "SGP",
    ccn3: "702",
  },
  GE: {
    cca2: "GE",
    cca3: "GEO",
    ccn3: "268",
  },
  BI: {
    cca2: "BI",
    cca3: "BDI",
    ccn3: "108",
  },
  NR: {
    cca2: "NR",
    cca3: "NRU",
    ccn3: "520",
  },
  SS: {
    cca2: "SS",
    cca3: "SSD",
    ccn3: "728",
  },
  WS: {
    cca2: "WS",
    cca3: "WSM",
    ccn3: "882",
  },
  CC: {
    cca2: "CC",
    cca3: "CCK",
    ccn3: "166",
  },
  CG: {
    cca2: "CG",
    cca3: "COG",
    ccn3: "178",
  },
  CY: {
    cca2: "CY",
    cca3: "CYP",
    ccn3: "196",
  },
  KW: {
    cca2: "KW",
    cca3: "KWT",
    ccn3: "414",
  },
  TT: {
    cca2: "TT",
    cca3: "TTO",
    ccn3: "780",
  },
  TV: {
    cca2: "TV",
    cca3: "TUV",
    ccn3: "798",
  },
  AO: {
    cca2: "AO",
    cca3: "AGO",
    ccn3: "024",
  },
  TO: {
    cca2: "TO",
    cca3: "TON",
    ccn3: "776",
  },
  GR: {
    cca2: "GR",
    cca3: "GRC",
    ccn3: "300",
  },
  MZ: {
    cca2: "MZ",
    cca3: "MOZ",
    ccn3: "508",
  },
  MM: {
    cca2: "MM",
    cca3: "MMR",
    ccn3: "104",
  },
  AT: {
    cca2: "AT",
    cca3: "AUT",
    ccn3: "040",
  },
  ET: {
    cca2: "ET",
    cca3: "ETH",
    ccn3: "231",
  },
  MQ: {
    cca2: "MQ",
    cca3: "MTQ",
    ccn3: "474",
  },
  AZ: {
    cca2: "AZ",
    cca3: "AZE",
    ccn3: "031",
  },
  UZ: {
    cca2: "UZ",
    cca3: "UZB",
    ccn3: "860",
  },
  BD: {
    cca2: "BD",
    cca3: "BGD",
    ccn3: "050",
  },
  AM: {
    cca2: "AM",
    cca3: "ARM",
    ccn3: "051",
  },
  NG: {
    cca2: "NG",
    cca3: "NGA",
    ccn3: "566",
  },
  ZA: {
    cca2: "ZA",
    cca3: "ZAF",
    ccn3: "710",
  },
  BN: {
    cca2: "BN",
    cca3: "BRN",
    ccn3: "096",
  },
  IT: {
    cca2: "IT",
    cca3: "ITA",
    ccn3: "380",
  },
  FI: {
    cca2: "FI",
    cca3: "FIN",
    ccn3: "246",
  },
  IL: {
    cca2: "IL",
    cca3: "ISR",
    ccn3: "376",
  },
  AW: {
    cca2: "AW",
    cca3: "ABW",
    ccn3: "533",
  },
  NI: {
    cca2: "NI",
    cca3: "NIC",
    ccn3: "558",
  },
  HT: {
    cca2: "HT",
    cca3: "HTI",
    ccn3: "332",
  },
  KI: {
    cca2: "KI",
    cca3: "KIR",
    ccn3: "296",
  },
  TC: {
    cca2: "TC",
    cca3: "TCA",
    ccn3: "796",
  },
  KY: {
    cca2: "KY",
    cca3: "CYM",
    ccn3: "136",
  },
  UA: {
    cca2: "UA",
    cca3: "UKR",
    ccn3: "804",
  },
  MX: {
    cca2: "MX",
    cca3: "MEX",
    ccn3: "484",
  },
  PS: {
    cca2: "PS",
    cca3: "PSE",
    ccn3: "275",
  },
  FJ: {
    cca2: "FJ",
    cca3: "FJI",
    ccn3: "242",
  },
  SK: {
    cca2: "SK",
    cca3: "SVK",
    ccn3: "703",
  },
  GH: {
    cca2: "GH",
    cca3: "GHA",
    ccn3: "288",
  },
  SR: {
    cca2: "SR",
    cca3: "SUR",
    ccn3: "740",
  },
  CU: {
    cca2: "CU",
    cca3: "CUB",
    ccn3: "192",
  },
  BT: {
    cca2: "BT",
    cca3: "BTN",
    ccn3: "064",
  },
  HU: {
    cca2: "HU",
    cca3: "HUN",
    ccn3: "348",
  },
  ST: {
    cca2: "ST",
    cca3: "STP",
    ccn3: "678",
  },
  IQ: {
    cca2: "IQ",
    cca3: "IRQ",
    ccn3: "368",
  },
  CZ: {
    cca2: "CZ",
    cca3: "CZE",
    ccn3: "203",
  },
  LT: {
    cca2: "LT",
    cca3: "LTU",
    ccn3: "440",
  },
  MP: {
    cca2: "MP",
    cca3: "MNP",
    ccn3: "580",
  },
  BW: {
    cca2: "BW",
    cca3: "BWA",
    ccn3: "072",
  },
  PA: {
    cca2: "PA",
    cca3: "PAN",
    ccn3: "591",
  },
  GA: {
    cca2: "GA",
    cca3: "GAB",
    ccn3: "266",
  },
  EC: {
    cca2: "EC",
    cca3: "ECU",
    ccn3: "218",
  },
};
