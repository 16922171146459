import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  InputLabel,
  MenuItem,
  FormControl,
  ListItemText,
  Select,
  Checkbox,
} from "@material-ui/core";

const SelectCheckMarks = ({ label, size, field, options }) => {
  const { name, onChange, value } = field;

  const [personName, setPersonName] = useState(value || []);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    const checkedItem = value.pop();
    const isValueExist = value.some(({ id }) => id === checkedItem.id);
    const newValue = isValueExist
      ? value.filter(({ id }) => id !== checkedItem.id)
      : [...value, checkedItem];

    setPersonName(newValue);
    onChange(newValue);
  };

  return (
    <FormControl size={size} fullWidth variant="outlined">
      <InputLabel>{label}</InputLabel>
      <Select
        id={name}
        label={label}
        multiple
        value={personName}
        onChange={handleChange}
        renderValue={(selected) =>
          selected?.map(({ abbreviation }) => abbreviation).join(", ")
        }
      >
        {options.map((option) => {
          const isChecked = personName.some(
            ({ abbreviation }) => abbreviation === option.abbreviation,
          );

          return (
            <MenuItem key={option.id} value={option}>
              <Checkbox checked={isChecked} color="primary" />
              <ListItemText primary={option.abbreviation} />
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

SelectCheckMarks.propTypes = {
  label: PropTypes.string,
  size: PropTypes.string,
  field: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
};

export default SelectCheckMarks;
