export const TITLE_BUTTONS = {
  CANCEL: "cancel",
  FINISH: "finish",
  DELETE: "delete",
  EDIT: "edit",
  CREATE_VENUE: "create venue",
  CREATE_TEAM: "create team",
  LOGIN: "Log In",
  CHOOSE_FILE: "Choose file",
};

export const PAGES_TITLES = {
  TEAMS: "Teams",
  VENUES: "Venues",
  USER_MANAGEMENT: "User management",
  ADMINISTRATION: "Administration",
};

export const TITLES = {
  DIVE_LIVE: "Dive Live",
  ADMIN_PANEL: "Administration panel",
  CREATE_TEAM: "Create a new team",
  CREATE_VENUE: "Venue",
  DELETE_MODAL: "Do you want to delete?",
  EDIT_TEAM: "Edit selected team",
};

export const ALERT_TEXT = {
  LOGIN: "Incorrect username or password",
  BAD_REQUEST: "The request has been failed",
  SUCCESS_UPDATE_TEAM: "The team updated successfully",
  SUCCESS_DELETE_TEAM: "The team removed successfully",
  SUCCESS_CREATE_TEAM: "The team created successfully",
  SUCCESS_UPDATE_VENUE: "The venue updated successfully",
  SUCCESS_DELETE_VENUE: "The venue removed successfully",
  SUCCESS_CREATE_VENUE: "The venue created successfully",
  BAD_FILE_FORMAT: "The format of selected file is bad",
};
