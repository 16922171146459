import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import {
  Container,
  Button,
  Typography,
  Box,
  TextField,
  IconButton,
} from "@material-ui/core";
import { Close, Search } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

import { addSearchValue } from "../../Redux/Reducers/commonReducer";
import { COLORS } from "../../constants/styles";
import { MIN_SEARCH_KEY_LENGTH, TIME_OUT_REQUEST } from "../../constants/data";

const userStyles = makeStyles({
  container: {
    padding: "25px 0",
  },
  wrapTitle: {
    backgroundColor: COLORS.BACKGROUND_GREEN,
    color: COLORS.TEXT_WHITE,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "5px 0",
    borderRadius: "5px 5px",
    marginBottom: "20px",
  },
  titleButton: {
    fontSize: "12px",
    fontWeight: "bold",
  },
  block: {
    display: "flex",
    justifyContent: "space-between",
    height: "40px",
  },
  blockSearch: {
    position: "relative",
    "& #search": {
      width: "70%",
    },
  },
  buttonIcon: {
    position: "absolute",
    right: "0",
    top: "0",
    bottom: "0",
    padding: "8px",
  },
});

const Header = ({ handleClickOpen, title, titleButton }) => {
  const classes = userStyles();
  const dispatch = useDispatch();
  const searchRef = useRef();

  const [valueSearch, setValueSearch] = useState("");

  const handleChange = ({ target }) => {
    setValueSearch(target.value);

    clearTimeout(searchRef.current);
    searchRef.current = setTimeout(() => {
      target.value.length < MIN_SEARCH_KEY_LENGTH
        ? dispatch(addSearchValue(""))
        : dispatch(addSearchValue(target.value));
    }, TIME_OUT_REQUEST);
  };

  const handleClearSearch = () => {
    dispatch(addSearchValue(""));
    setValueSearch("");
  };

  return (
    <Container data-container="container" className={classes.container}>
      <Box className={classes.wrapTitle}>
        <Typography>{title}</Typography>
      </Box>
      <Box className={classes.block}>
        <Button variant="outlined" onClick={handleClickOpen}>
          <Typography className={classes.titleButton}>{titleButton}</Typography>
        </Button>
        <Box className={classes.blockSearch}>
          <TextField
            id="search"
            label="Search"
            variant="outlined"
            size="small"
            onChange={handleChange}
            ref={searchRef}
            value={valueSearch}
          />
          <IconButton
            sx={{ p: "10px" }}
            className={classes.buttonIcon}
            onClick={handleClearSearch}
          >
            {valueSearch ? <Close /> : <Search />}
          </IconButton>
        </Box>
      </Box>
    </Container>
  );
};

Header.propTypes = {
  handleClickOpen: PropTypes.func,
  title: PropTypes.string,
  titleButton: PropTypes.string,
};

export default Header;
