import React from "react";
import PropTypes from "prop-types";
import { Checkbox, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

const updateItem = (option) => {
  const clone = { ...option };
  delete clone.id;
  const title = Object.values(clone)
    .filter((e) => !!e)
    .join(", ");
  return { id: option.id, title };
};

const SelectCheckboxFilter = ({ field, label, size, options }) => {
  const { value, onChange } = field;
  const handleChange = (e, newValue) => onChange(newValue);

  return (
    <Autocomplete
      multiple
      id="checkboxes-tags-demo"
      options={options}
      value={value}
      onChange={handleChange}
      disableCloseOnSelect
      getOptionLabel={(option) => updateItem(option).title}
      getOptionSelected={(option, value) =>
        updateItem(option).id === updateItem(value).id
      }
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <Checkbox
            style={{ marginRight: 8 }}
            checked={selected}
            color="primary"
          />
          {updateItem(option).title}
        </React.Fragment>
      )}
      size={size}
      fullWidth
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={label}
          placeholder={"Search"}
        />
      )}
    />
  );
};

SelectCheckboxFilter.propTypes = {
  options: PropTypes.array.isRequired,
  field: PropTypes.object,
  label: PropTypes.string,
  size: PropTypes.string,
};

export default SelectCheckboxFilter;
