export const GET_ALL_VENUES = "venues/all";
export const GET_SEARCH_VENUES = (key) => `venues/search/${key}`;
export const GET_ALL_VENUES_SHORT = (sortcolumn) => `venues/all/${sortcolumn}`;
export const GET_VENUE = (venueId) => `venues/${venueId}`;
export const DELETE_VENUE = (venueId) => `venues/delete/${venueId}`;
export const EDIT_VENUE = "venues/update";
export const CREATE_VENUE = "venues/create";

export const SET_LOGIN = "auth/login/admin";

export const GET_ALL_TEAMS = "admin/teams/all";
export const GET_SEARCH_TEAMS = (key) => `admin/teams/search/${key}`;
export const DELETE_TEAM = (teamId) => `admin/teams/delete/${teamId}`;
export const EDIT_TEAM = "admin/teams/update";
export const CREATE_TEAM = "admin/teams/create";

export const GET_ALL_GOVERNING_BODIES = "governingbody/all";
export const GET_CONFERENCES = "conferences/all";
export const GET_ALL_COUNTRIES_ISO = "country/all";
export const GET_STATES_BY_COUNTRY_ISO = (country) => `state/${country}`;
