import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import {
  AppBar,
  Container,
  Toolbar,
  Box,
  Typography,
  Button,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import { COLORS } from "../../constants/styles";
import logo from "../../assets/dive-logo.png";
import logout from "../../assets/logo/logout.svg";

import { pages } from "../../constants/data";

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: COLORS.BACKGROUND_LIGHT_GREY,
    position: "sticky",
    top: "0",
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  menuText: {
    color: COLORS.TEXT_GREY,
  },
  link: {
    textDecoration: "none",
    marginRight: "15px",
    "& :hover": {
      color: COLORS.TEXT_GREEN,
      transform: "scale(1.1)",
    },
  },
  activeLink: {
    textDecoration: "none",
    marginRight: "15px",
    "& p": {
      color: COLORS.TEXT_GREEN,
    },
  },
  logo: {
    width: "60px",
    height: "50px",
    backgroundImage: `url(${logo})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
  },
  logout: {
    width: "24px",
    height: "18px",
  },
}));

const TopBar = ({ logOut }) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <AppBar position="static" className={classes.appBar}>
        <Container data-container="container" className={classes.container}>
          <Toolbar>
            <Box className={classes.logo} />
            <Box sx={{ flexGrow: 1, display: "flex" }}>
              {pages.map((page) => (
                <NavLink
                  key={page.id}
                  to={page.link}
                  className={(navData) =>
                    navData.isActive ? classes.activeLink : classes.link
                  }
                >
                  <Typography textalign="center" className={classes.menuText}>
                    {page.title}
                  </Typography>
                </NavLink>
              ))}
            </Box>
          </Toolbar>
          <Button onClick={logOut}>
            <img src={logout} alt="logout" className={classes.logout} />
          </Button>
        </Container>
      </AppBar>
    </React.Fragment>
  );
};

TopBar.propTypes = {
  logout: PropTypes.func,
};

export default TopBar;
