import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

const AutocompleteField = ({ data, field, label, size, isDisabled }) => {
  const { name, value, onChange, required, ...other } = field;

  const [inputValue, setInputValue] = useState(value);

  const handleChange = (event, newValue) => {
    setInputValue(newValue);
  };

  const getOptionLabel = (option) => option?.label || "";

  const getOptionSelected = (option, value) => option?.id === value?.id;

  const renderOption = (option) => (
    <>
      {option.iconBefore}
      {option.label}
    </>
  );

  useEffect(() => {
    onChange(inputValue);
  }, [inputValue]);

  useEffect(() => {
    isDisabled && setInputValue(null);
  }, [isDisabled]);

  return (
    <Autocomplete
      disablePortal
      id={name}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      renderOption={renderOption}
      options={data}
      value={inputValue}
      onChange={handleChange}
      disabled={isDisabled}
      renderInput={(params) => (
        <TextField
          {...params}
          autoComplete={name}
          label={label}
          variant="outlined"
          fullWidth
          size={size}
          required={required}
          InputProps={{
            ...params.InputProps,
            startAdornment: inputValue?.iconBefore
              ? inputValue?.iconBefore
              : params.InputProps.startAdornment,
          }}
        />
      )}
      name={name}
      size={size}
      {...other}
    />
  );
};

AutocompleteField.propTypes = {
  data: PropTypes.array.isRequired,
  field: PropTypes.object,
  label: PropTypes.string,
  size: PropTypes.string,
  title: PropTypes.string,
  isDisabled: PropTypes.bool,
};

export default AutocompleteField;
