import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Box, Button, Typography } from "@material-ui/core";

import { COLORS } from "../../constants/styles";

const useState = makeStyles({
  fieldButtons: {
    display: "flex",
    justifyContent: "center",
    padding: "10px 0",
  },
  cansel: {
    width: "80px",
    height: "30px",
    "& p": {
      fontSize: "0.8rem",
    },
  },
  submit: {
    width: "80px",
    height: "30px",
    backgroundColor: COLORS.BACKGROUND_GREEN,
    marginRight: "10px",
    "& p": {
      color: COLORS.TEXT_WHITE,
      fontSize: "0.8rem",
    },
  },
});

const ButtonsGroup = ({
  handleClose,
  handleFunction,
  submitTitle,
  closeTitle,
}) => {
  const classes = useState();

  return (
    <Box className={classes.fieldButtons}>
      <Button
        className={classes.submit}
        type="submit"
        variant="contained"
        onClick={() => {
          handleClose();
          handleFunction();
        }}
      >
        <Typography>{submitTitle}</Typography>
      </Button>
      <Button
        className={classes.cansel}
        variant="outlined"
        onClick={handleClose}
      >
        <Typography>{closeTitle}</Typography>
      </Button>
    </Box>
  );
};

ButtonsGroup.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleFunction: PropTypes.func.isRequired,
  submitTitle: PropTypes.string.isRequired,
  closeTitle: PropTypes.string.isRequired,
};

export default ButtonsGroup;
