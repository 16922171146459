import { configureStore } from "@reduxjs/toolkit";

import venuesReducer from "../pages/Venues/sliceReducer";
import userReducer from "./Reducers/userReducer";
import teamsReducer from "../pages/Teams/sliceReducer";
import commonReducer from "./Reducers/commonReducer";

export const store = configureStore({
  reducer: {
    venues: venuesReducer,
    user: userReducer,
    teams: teamsReducer,
    common: commonReducer,
  },
});
