import { isEmpty } from "lodash";
import { fetchJson } from "./fetch";

export const dataProvider = (apiUrl) => ({
  getList: async (resource, params) => {
    const query = {};

    if (params) {
      const { limit, page, sort, isAscending } = params;

      if (page?.toString() && page !== "undefined") {
        query.page = page;
      }
      if (limit?.toString() && limit !== "undefined") {
        query.size = limit;
      }
      if (sort && sort !== "undefined") {
        query.sort = sort;
      }
      query.isAscending = !!isAscending;
    }

    let url = `${apiUrl}/${resource}`;

    if (!isEmpty(query)) {
      const string = Object.values(query).reduce(
        (acc, elem) => `${acc}/${elem}`,
        "",
      );

      url += string;
    }

    return fetchJson(url).then(({ json }) => json);
  },

  delete: async (resource) => {
    let url = `${apiUrl}/${resource}`;

    return fetchJson(url, {
      method: "POST",
    }).then(({ json }) => json);
  },

  create: async (resource, params) => {
    let url = `${apiUrl}/${resource}`;

    return fetchJson(url, {
      method: "POST",
      body: JSON.stringify(params),
    }).then(({ json }) => json);
  },

  update: async (resource, params) => {
    let url = `${apiUrl}/${resource}`;

    return fetchJson(url, {
      method: "POST",
      body: JSON.stringify(params),
    }).then(({ json }) => json);
  },
});
