import React from "react";
import { format } from "date-fns";
import PropTypes from "prop-types";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  Typography,
  TableCell,
  Container,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { TITLE_BUTTONS } from "../../constants/titles";
import { COLORS } from "../../constants/styles";

import ListPagination from "../ListPagination";

const useStyles = makeStyles({
  container: {
    paddingLeft: "0px",
    paddingRight: "0px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    fontSize: "10px",
    fontWeight: "bold",
  },
  cell: {
    padding: "10px 5px",
    textAlign: "center",
  },
  body: {
    fontSize: "12px",
    whiteSpace: "break-spaces",
  },
  button: {
    padding: "0px",
  },
  buttonTitle: {
    color: COLORS.TEXT_GREEN,
    fontSize: "9px",
    fontWeight: "bold",
  },
  rowGrey: {
    backgroundColor: COLORS.BACKGROUND_GREY,
  },
  rowWhite: {
    backgroundColor: COLORS.BACKGROUND_WHITE,
  },
});

const DataTable = ({
  updateItem,
  fetchData,
  pagination,
  removeRow,
  onChangePage,
  titlesHead,
  updateData,
  fieldsRow,
}) => {
  const classes = useStyles();

  return (
    <Container data-container="container" className={classes.container}>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              {titlesHead.map((title, i) => (
                <TableCell key={i} className={classes.cell}>
                  <Typography className={classes.title}>{title}</Typography>
                </TableCell>
              ))}
              <TableCell className={classes.cell}>
                <Typography className={classes.title}>Delete</Typography>
              </TableCell>
              <TableCell className={classes.cell}>
                <Typography className={classes.title}>Edit</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {updateData(fetchData).map((row, index) => {
              const styleRow = index % 2 ? classes.rowWhite : classes.rowGrey;
              const initialRowValue = fetchData.find(
                ({ id }) => id === row?.id,
              );

              return (
                <TableRow key={row.id} className={styleRow}>
                  {Object.keys(fieldsRow).map((key) => {
                    const body = (function () {
                      switch (key) {
                        case "creationDate":
                          return (
                            row[key] && format(new Date(row[key]), "MM/dd/yyyy")
                          );
                        case "conference":
                          return row[key]
                            ? initialRowValue[key].name
                            : row[key];
                        case "governingBodies":
                          return row[key]
                            ?.map(({ abbreviation }) => abbreviation)
                            ?.join(", ");
                        default:
                          return row[key];
                      }
                    })();

                    return (
                      <TableCell key={key} className={classes.cell}>
                        <Typography className={classes.body}>{body}</Typography>
                      </TableCell>
                    );
                  })}
                  <TableCell className={classes.cell}>
                    <Button
                      variant="text"
                      className={classes.button}
                      onClick={() => removeRow(row.id)}
                    >
                      <Typography className={classes.buttonTitle}>
                        {TITLE_BUTTONS.DELETE}
                      </Typography>
                    </Button>
                  </TableCell>
                  <TableCell className={classes.cell}>
                    <Button
                      variant="text"
                      className={classes.button}
                      onClick={() => updateItem(row)}
                    >
                      <Typography className={classes.buttonTitle}>
                        {TITLE_BUTTONS.EDIT}
                      </Typography>
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <ListPagination filters={pagination} fetchFunction={onChangePage} />
    </Container>
  );
};

DataTable.propTypes = {
  updateItem: PropTypes.func,
  fetchData: PropTypes.array,
  pagination: PropTypes.shape({
    limit: PropTypes.number,
    page: PropTypes.number,
    total: PropTypes.number,
  }),
  removeRow: PropTypes.func,
  onChangePage: PropTypes.func,
  titlesHead: PropTypes.array,
  updateData: PropTypes.func,
  fieldsRow: PropTypes.object,
};

export default DataTable;
