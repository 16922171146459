import React from "react";
import PropTypes from "prop-types";
import {
  IconButton,
  OutlinedInput,
  InputLabel,
  InputAdornment,
  FormControl,
  FormHelperText,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

import view from "../../assets/view.png";
import hidden from "../../assets/hidden.png";

const useStyles = makeStyles({
  iconView: {
    width: "28px",
    height: "28px",
    background: `url(${view}) no-repeat center`,
    backgroundSize: "contain",
  },
  iconHidden: {
    width: "28px",
    height: "28px",
    background: `url(${hidden}) no-repeat center`,
    backgroundSize: "contain",
  },
});

const InputPassword = ({ field, fieldState, label }) => {
  const classes = useStyles();
  const [values, setValues] = React.useState({ password: "" });
  const { error, invalid } = fieldState;

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <FormControl
      variant="outlined"
      margin="normal"
      size="small"
      fullWidth
      {...field}
      error={invalid}
    >
      <InputLabel htmlFor="password">{label}</InputLabel>
      <OutlinedInput
        id="password"
        type={values.showPassword ? "text" : "password"}
        value={values.password}
        onChange={handleChange("password")}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {values.showPassword ? (
                <div className={classes.iconHidden} />
              ) : (
                <div className={classes.iconView} />
              )}
            </IconButton>
          </InputAdornment>
        }
        label={label}
      />
      <FormHelperText id="my-helper-text">{error?.message}</FormHelperText>
    </FormControl>
  );
};

InputPassword.propTypes = {
  field: PropTypes.object,
  fieldState: PropTypes.object,
  label: PropTypes.string,
};

export default InputPassword;
