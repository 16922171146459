import React from "react";
import { useDispatch } from "react-redux";
import { Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import waves from "../../assets/waves-bottom.png";
import diveLogo from "../../assets/dive-logo.png";
import { TITLES } from "../../constants/titles";

import LoginForm from "./LoginForm";
import { userLogIn } from "../../Redux/Reducers/userReducer";

const useStyles = makeStyles({
  root: {
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    background: `url(${waves}) center bottom / 100% 50% no-repeat`,
  },
  loginField: {
    maxWidth: "903px",
    width: "95%",
    background: "rgba(255, 0, 0, 0)",
    position: "relative",
    height: "567px",
    padding: "0px",
    boxShadow: "rgb(0 0 0 / 20%) 0px 2px 20px 0px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    overflow: "auto",
  },
  leftPart: {
    width: "26%",
    height: "100%",
    background: "rgb(5, 224, 235)",
    position: "relative",
  },
  logoField: {
    borderRadius: " 100%",
    backgroundColor: "rgb(5, 224, 235)",
    position: "absolute",
    top: "calc(50% - 92px)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "180px",
    height: "180px",
    right: "-90px",
  },
  logoTitle: {
    fontSize: "23px",
    letterSpacing: "0px",
    lineHeight: "20px",
    marginTop: "10px",
  },
  logoImage: {
    width: "72px",
    height: "72px",
  },
  rightPart: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
});

const Login = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onSubmit = (params) => dispatch(userLogIn(params));

  return (
    <>
      <Box component="main" className={classes.root}>
        <Box component="section" className={classes.loginField}>
          <Box className={classes.leftPart}>
            <Box className={classes.logoField}>
              <img
                src={diveLogo}
                alt={TITLES.DIVE_LIVE}
                className={classes.logoImage}
              />
              <Typography className={classes.logoTitle}>
                {TITLES.DIVE_LIVE}
              </Typography>
            </Box>
          </Box>
          <Box className={classes.rightPart}>
            <LoginForm onSubmit={onSubmit} />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Login;
