export const pages = [
  { id: 1, title: "Teams", link: "teams" },
  { id: 2, title: "Venues", link: "venues" },
  { id: 3, title: "User management", link: "user" },
];

export const VENUE_FIELDS_TITLE = {
  id: "ID",
  poolName: "Pool name",
  schoolInstitution: "School/Institution",
  aquaticCenter: "Aquatic center",
  building: "Building",
  country: "Country",
  county: "County",
  state: "State",
  city: "City",
  streetOne: "Street 1",
  streetTwo: "Street 2",
  streetThree: "Street 3",
  postalCode: "Postal code",
  email: "Email",
  backupEmail: "Backup email",
  googleMapsLink: "Google map link",
  mobilePhone: "Mobile phone",
  website: "Website",
};

export const REQUIRED_FIELDS = ["country", "state", "city"];

export const TEAM_FIELDS_TITLE = {
  id: "ID",
  name: "Name",
  divisionId: "Division ID",
  conference: "Conference ID",
  abbreviation: "Abbreviation",
  diveMeetsId: "DiveMeetsId",
  mascotName: "Plural mascot name",
  regionId: "Region ID",
  city: "City",
  state: "State",
  county: "County",
  country: "Country",
  email: "Email",
  website: "Website",
  mobilePhone: "Mobile phone",
  governingBodies: "Governing bodies",
  gpsCoordinates: "GPS coordinates",
  userCount: "User count",
  creationDate: "Date",
};

export const formValueFields = {
  poolName: "",
  aquaticCenter: "",
  building: "",
  streetOne: "",
  streetTwo: "",
  streetThree: "",
  city: "",
  state: "",
  county: "",
  postalCode: "",
  country: "",
  email: "",
  backupEmail: "",
  googleMapsLink: "",
  mobilePhone: "",
  website: "",
  schoolInstitution: "",
};

export const formLoginFields = {
  username: "username",
  password: "password",
};

export const formTeamFields = {
  name: { short: "name", title: "Team name" },
  governingBodies: { short: "governingBodies", title: "Governing bodies" },
  coachEmail: { short: "coachEmail", title: "Coach email" },
  teamInfo: {
    short: "teamInfo",
    title: "Team info",
    data: [
      { id: 1, short: "divisionId", title: "Division ID" },
      { id: 2, short: "conference", title: "Conference ID" },
      { id: 3, short: "abbreviation", title: "Abbreviation" },
      { id: 4, short: "diveMeetsId", title: "Dive meets ID" },
      { id: 5, short: "mascotName", title: "Plural mascot name" },
      { id: 6, short: "regionId", title: "Region ID" },
    ],
  },
  contactInformation: {
    short: "contactInformation",
    title: "Contact information",
    data: [
      { id: 8, short: "country", title: "Country" },
      { id: 6, short: "county", title: "County" },
      { id: 5, short: "state", title: "State" },
      { id: 4, short: "city", title: "City" },
      { id: 1, short: "streetOne", title: "Street" },
      { id: 2, short: "streetTwo", title: "Street 2" },
      { id: 3, short: "streetThree", title: "Street 3" },
      { id: 14, short: "gpsCoordinates", title: "GPS coordinates" },
      { id: 7, short: "postalCode", title: "Postal code" },
      { id: 12, short: "email", title: "Email" },
      { id: 13, short: "backupEmail", title: "Backup email" },
      { id: 9, short: "googleMapsLink", title: "Google map link" },
      { id: 11, short: "mobilePhone", title: "Mobile phone" },
      { id: 10, short: "workPhone", title: "Work phone" },
      { id: 15, short: "website", title: "Website" },
    ],
  },
  venues: { short: "venues", title: "Venues" },
  cloudAlias: { short: "cloudAlias", title: "Logo" },
};

export const dtoFormFields = {
  addressDto: {
    backupEmail: "",
    city: "",
    country: "",
    county: "",
    email: "",
    googleMapsLink: "",
    gpsCoordinates: "",
    id: "",
    mobilePhone: "",
    postalCode: "",
    state: "",
    streetOne: "",
    streetThree: "",
    streetTwo: "",
    website: "",
    workPhone: "",
  },
  aquaticCenter: "",
  building: "",
  city: "",
  country: "",
  firstLine: "",
  id: "",
  poolName: "",
  secondLine: "",
  schoolInstitution: "",
};

export const dtoFormTeamFields = {
  abbreviation: "",
  coachEmail: "",
  conference: "",
  contactInformation: {
    backupEmail: "",
    city: "",
    country: "",
    county: "",
    email: "",
    googleMapsLink: "",
    gpsCoordinates: "",
    id: "",
    mobilePhone: "",
    postalCode: "",
    state: "",
    streetOne: "",
    streetThree: "",
    streetTwo: "",
    website: "",
    workPhone: "",
  },
  diveMeetsId: "",
  divisionId: "",
  governingBodies: [],
  id: "",
  cloudAlias: "",
  mascotName: "",
  name: "",
  regionId: "",
  userCount: "",
  venues: [],
};

export const defaultParamsViewTable = {
  page: 0,
  limit: 10,
  sort: "Id",
  isAscending: true,
};

export const defaultParamsTeamsTable = {
  page: 0,
  limit: 10,
  sort: "ID",
  isAscending: true,
};

export const MIN_SEARCH_KEY_LENGTH = 3;
export const TIME_OUT_REQUEST = 500;

export const ENVS = {
  LOCAL: {
    DOMAIN: "diving-dev.scoresandmore.live",
    GCP_BUCKET: "diving-dev-scoresandmore-live",
  },
  DEV: {
    DOMAIN: "diving-dev.scoresandmore.live",
    GCP_BUCKET: "diving-dev-scoresandmore-live",
  },
  QA: {
    DOMAIN: "diving-qa.scoresandmore.live",
    GCP_BUCKET: "diving-qa-scoresandmore-live",
  },
  PROD: {
    DOMAIN: "diving.scoresandmore.live",
    GCP_BUCKET: "diving-scoresandmore-live",
  },
};
export const GCP_URL = "https://storage.googleapis.com";
const currentGcpBucket = ENVS[process.env.REACT_APP_ENV].GCP_BUCKET;
export const GCP_BUCKET_URL_AVATARS = `${GCP_URL}/${currentGcpBucket}/logo_images/`;
export const GCP_BUCKET_URL_FLAGS = `${GCP_URL}/${ENVS.PROD.GCP_BUCKET}/countryFlags/`;
