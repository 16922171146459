import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getList, deleteOne, create, update } from "../../dataProvider/API";
import { toast } from "react-toastify";

import { ALERT_TEXT } from "../../constants/titles";

const initialState = {
  loading: false,
  fetchData: {},
  fetchDataShortVenues: [],
};

export const getVenues = createAsyncThunk(
  "venues/getDataVenues",
  ({ endpoint, params }) => getList(endpoint, params),
);

export const getVenuesShort = createAsyncThunk(
  "venues/getVenuesShort",
  ({ endpoint }) => getList(endpoint),
);

export const deleteVenue = createAsyncThunk("venues/deleteVenue", (endpoint) =>
  deleteOne(endpoint),
);

export const createVenue = createAsyncThunk(
  "venues/create",
  ({ endpoint, data }) => create(endpoint, data),
);

export const updateVenue = createAsyncThunk(
  "venues/update",
  ({ endpoint, data }) => update(endpoint, data),
);

export const venuesSlice = createSlice({
  name: "venues",
  initialState,
  extraReducers: {
    [getVenues.pending]: (state) => {
      state.loading = true;
    },
    [getVenues.fulfilled]: (state, action) => {
      state.fetchData = action.payload;
      state.loading = false;
    },
    [deleteVenue.pending]: (state) => {
      state.loading = true;
    },
    [deleteVenue.fulfilled]: (state) => {
      state.loading = false;
      toast.success(ALERT_TEXT.SUCCESS_DELETE_VENUE);
    },
    [deleteVenue.rejected]: (state) => {
      state.loading = false;
      toast.error(ALERT_TEXT.BAD_REQUEST);
    },
    [getVenuesShort.fulfilled]: (state, action) => {
      state.fetchDataShortVenues = action.payload;
    },
    [createVenue.fulfilled]: () => {
      toast.success(ALERT_TEXT.SUCCESS_CREATE_VENUE);
    },
    [createVenue.rejected]: () => {
      toast.error(ALERT_TEXT.BAD_REQUEST);
    },
    [updateVenue.fulfilled]: () => {
      toast.success(ALERT_TEXT.SUCCESS_UPDATE_VENUE);
    },
    [updateVenue.rejected]: () => {
      toast.error(ALERT_TEXT.BAD_REQUEST);
    },
  },
});

export default venuesSlice.reducer;
