export const updateDataVenues = (dataVenues) => {
  return dataVenues.map((dataVenue) => {
    const {
      id,
      aquaticCenter,
      building,
      poolName,
      schoolInstitution,
      addressDto,
    } = dataVenue;

    return {
      ...addressDto,
      id,
      aquaticCenter,
      building,
      poolName,
      schoolInstitution,
    };
  });
};

export const updateDataTeams = (dataTeams) => {
  return dataTeams.map((dataTeam) => {
    const { id, contactInformation, conference, ...rest } = dataTeam;

    return {
      ...rest,
      ...contactInformation,
      id,
      contactInformationID: contactInformation?.id,
      conference: conference?.id,
    };
  });
};
