import React from "react";
import PropTypes from "prop-types";
import { TextField } from "@material-ui/core";

const TextForm = ({
  field,
  label,
  size,
  fieldState,
  valueNormalizer = (value) => value,
}) => {
  const { name, value, ...other } = field;
  const { error, invalid } = fieldState;
  const fieldValue = value === null ? "" : valueNormalizer(value);

  return (
    <TextField
      id={name}
      autoComplete={name}
      label={label}
      variant="outlined"
      fullWidth
      value={fieldValue}
      error={invalid}
      helperText={error?.message}
      size={size}
      {...other}
    />
  );
};

TextForm.propTypes = {
  field: PropTypes.object,
  label: PropTypes.string,
  size: PropTypes.string,
  fieldState: PropTypes.object,
};

export default TextForm;
